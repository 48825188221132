import { FunctionComponent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { faBars, faBorderAll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../../global/colors';
import { ObjectKeys } from '../../../../types/objectKeys';
import useWindowSize from '../../../../hooks/useResize';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useYearsStore from '../../../../store/years';
import useCurrencyStore from '../../../../store/currency';
import SelectComponent from '../../../../components/select';
import Search from '../../../../components/input/search';

interface Props {
    setParams: Dispatch<SetStateAction<any>>;
    params: ObjectKeys;
    searchValue?: string;

    setSearchValue: Dispatch<SetStateAction<string>>;
    setDisplay: Dispatch<SetStateAction<string>>;
}

const Filters: FunctionComponent<Props> = ({ setParams, params, searchValue, setSearchValue, setDisplay }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { years } = useYearsStore();
    const { currency } = useCurrencyStore();
    const size = useWindowSize();
    const [renderKey, setRenderKey] = useState(0);
    const [showSearch, setShowSearch] = useState(false);

    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);
    const [status, setStatus] = useState<Array<{ value: string; label: string }>>([]);
    const [isLaptopWidth, setIsLaptopWidth] = useState(!!size.width && size.width > 1535);

    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });
        const yearsData = years?.map((year) => {
            return { value: year, label: year };
        });
        setRenderKey((prev) => prev + 1);
        setCurrencies([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...currencyData]);
        setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsData]);
    }, [currentLang, useYearsStore()]);

    useEffect(() => {
        setIsLaptopWidth(!!size.width && size.width > 1535);
    }, [size.width]);

    useEffect(() => {
        setStatus([
            { value: '', label: t('pages.invoices.filters.status.all').text },
            { value: 'sent', label: t('pages.invoices.filters.status.sent').text },
            { value: 'open', label: t('pages.invoices.filters.status.draft').text },
            { value: 'sent', label: t('pages.invoices.filters.status.unpaid').text },
            { value: 'cancelled', label: t('pages.invoices.filters.status.void').text },
            { value: 'closed', label: t('pages.advanceInvoices.filters.status.closed').text },
        ]);
    }, [currentLang]);

    return (
        <FiltersWrapper>
            <TopFilters>
                <FiltersInner>
                    {!isLaptopWidth ? (
                        <SelectComponent
                            className="select-container"
                            optionList={status}
                            label={t('pages.invoices.filters.statusFilter').text}
                            handleSelectedValue={(value: string) =>
                                setParams({
                                    ...params,
                                    search: { ...params.search, status: value },
                                    page: 1,
                                    searchFields:
                                        value !== ''
                                            ? { ...params.searchFields, status: 'in' }
                                            : { ...params.searchFields, status: value },
                                })
                            }
                            defaultSelectedOption={status[0]}
                        />
                    ) : (
                        <StatusFilter className={`${params?.search?.status ? '' : 'first-active'}`}>
                            <label>status</label>
                            {status.map((s) => {
                                return (
                                    <p
                                        className={`${
                                            params?.search?.status === s?.value ? 'active' : ''
                                        } cursor-pointer`.trim()}
                                        key={nanoid()}
                                        onClick={() => {
                                            setParams({
                                                ...params,
                                                search: { ...params.search, status: s.value },
                                                page: 1,
                                                searchFields:
                                                    s.value !== ''
                                                        ? { ...params.searchFields, status: 'in' }
                                                        : { ...params.searchFields, status: s.value },
                                            });
                                        }}
                                    >
                                        {s.label}
                                    </p>
                                );
                            })}
                        </StatusFilter>
                    )}
                    <SelectComponent
                        className="select-container"
                        optionList={currencies}
                        label={t('pages.invoices.filters.currency.label').text}
                        handleSelectedValue={(value: string) =>
                            setParams({
                                ...params,
                                search: { ...params.search, currency: value },
                                page: 1,
                                searchFields:
                                    value !== ''
                                        ? { ...params.searchFields, currency: '=' }
                                        : { ...params.searchFields, currency: value },
                            })
                        }
                        defaultSelectedOption={currencies[0]}
                        key={renderKey}
                    />
                    <SelectComponent
                        className="select-container"
                        optionList={yearsOptions}
                        label={t('pages.invoices.filters.year.label').text}
                        handleSelectedValue={(value: string) => {
                            const parsedYear = parseInt(value);
                            const date = parsedYear - 1 + '-12-31,' + (parsedYear + 1) + '-01-01';
                            setParams({
                                ...params,
                                page: 1,
                                search:
                                    value !== ''
                                        ? { ...params.search, invoice_date: date }
                                        : { ...params.search, invoice_date: value },
                                searchFields:
                                    value !== ''
                                        ? {
                                              ...params.searchFields,
                                              invoice_date: 'between',
                                          }
                                        : {
                                              ...params.searchFields,
                                              invoice_date: value,
                                          },
                            });
                        }}
                        defaultSelectedOption={yearsOptions[0]}
                    />
                </FiltersInner>
                <DisplayIcons>
                    <FontAwesomeIcon
                        icon={faBars}
                        color={colors.white}
                        className="cursor-pointer"
                        onClick={() => setDisplay('list')}
                    />
                    <FontAwesomeIcon
                        icon={faBorderAll}
                        color={colors.white}
                        className="cursor-pointer"
                        onClick={() => setDisplay('grid')}
                    />
                </DisplayIcons>
            </TopFilters>
            <BottomFilters>
                <ShowSelect>
                    <SelectComponent
                        optionList={showItems}
                        label={t('pages.invoices.filters.show.label').text}
                        handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                        defaultSelectedOption={showItems[0]}
                    />
                    <p>{t('pages.advanceInvoices.filters.show.aInvoice').text}</p>
                </ShowSelect>
                <Search
                    placeholder={t('pages.invoices.filters.search').text}
                    value={searchValue}
                    onChange={(value: string) => {
                        setSearchValue(value);
                        setShowSearch(true);
                    }}
                    onLeaveOrEnter={(value: string) => {
                        setShowSearch(false);
                        setSearchValue(value);
                        setParams({
                            ...params,
                            search: { ...params.search, 'client.company_name': value },
                            page: 1,
                            searchFields:
                                value !== ''
                                    ? { ...params.searchFields, 'client.company_name': 'like' }
                                    : { ...params.searchFields, 'client.company_name': value },
                        });
                    }}
                    showSearchNote={showSearch}
                />
            </BottomFilters>
        </FiltersWrapper>
    );
};

const FiltersWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    .select-wrapper {
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        label {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            margin-right: 6px;
        }
        .select__control {
            background: var(--white);
            color: var(--gray);
        }
        > div {
            display: flex;
            align-items: center;
            @media screen and (max-width: 426px) {
                display: flow;
            }
        }
    }
`;
const TopFilters = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: var(--purple);
    color: var(--white);
    justify-content: space-between;
    .select-wrapper {
        margin-right: 20px;
        width: fit-content;
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;
const FiltersInner = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    align-items: center;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    .select-container {
        > div {
            width: 120px;
        }
        div {
            font-size: 14px;
        }
    }
`;
const StatusFilter = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    &.first-active {
        p {
            &:nth-of-type(1) {
                border-bottom: 1px solid var(--border-color);
            }
        }
    }
    label {
        margin-right: 6px;
        font-size: 14px;
    }
    p {
        font-size: 14px;
        border-bottom: 1px solid transparent;
        padding: 11px 8px;
        white-space: nowrap;
        &.active {
            border-bottom: 1px solid var(--border-color);
        }
    }
`;
const DisplayIcons = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-left: 15px;
        font-size: 22px;
    }
    @media screen and (max-width: 768px) {
        justify-content: flex-end;
    }
`;
const BottomFilters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .search-wrapper {
        input {
            width: 100%;
        }
        width: 150px;
    }
`;
const ShowSelect = styled.div`
    display: flex;
    align-items: center;
    .select-wrapper {
        width: auto;
        label {
            color: var(--gray);
        }
    }
    p {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        color: var(--gray);
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
`;
export default Filters;
