import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import Input, { InputComponentType } from '../../../../components/input';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import SelectSearch from '../../../../components/selectSearch';
import useUserStore from '../../../../store/user';
import communication from '../../../../communication';
import { CreateHealthCardsType, HealthCardsType } from '../../../../types/communication/healthCards';
import moment from 'moment';
import useNumLengthValidation from '../../../../hooks/useNumLengthValidation';
import useAgencyStore from '../../../../store/agency';
import useCountriesStore from '../../../../store/countries';

interface Props {
    t: Function;
    close: (validate: boolean) => void;
    cardsLength?: number;
    loading: Function;
    finishLoading: Function;
    changeModal: Function;
    edit?: boolean;
    editData?: HealthCardsType;
    success?: Function;
    currentLang: string;
    setFormHasBeenEdited: (value: boolean) => void;
    setFormHasDirtyData: (value: boolean) => void;
}

const NewHealthCardModal: FunctionComponent<Props> = ({
    t,
    close,
    cardsLength,
    loading,
    finishLoading,
    changeModal,
    edit,
    editData,
    currentLang,
    setFormHasBeenEdited,
    setFormHasDirtyData,
}) => {
    const genders = [
        {
            value: 'male',
            label: t('pages.agency.personalInfo.genders.male').text,
        },
        {
            value: 'female',
            label: t('pages.agency.personalInfo.genders.female').text,
        },
    ];
    const municipalities = useUserStore.getState().municipalities;
    const [showError, setShowError] = useState(false);
    const [showEditError, setShowEditError] = useState(false);

    const { agency } = useAgencyStore();
    const { countries } = useCountriesStore();
    const [citizenshipOptions, setCitizenshipOptions] = useState<Array<{ value: string; label: string }>>([]);

    const [formData, setFormData] = useState<CreateHealthCardsType>({
        first_name: cardsLength === 0 ? agency.user.data.first_name : '',
        last_name: cardsLength === 0 ? agency.user.data.last_name : '',
        parent_name: cardsLength === 0 ? agency.user.data.middle_name : '',
        jmbg: cardsLength === 0 ? agency.user.data.jmbg : '',
        street_address: cardsLength === 0 ? agency.user.data.address : '',
        street_number: cardsLength === 0 ? agency.user.data.street_number : '',
        city: cardsLength === 0 ? agency.user.data.city : '',
        education: cardsLength === 0 ? agency.user.data.education : '',
        municipality_id: cardsLength === 0 ? agency.user.data.municipality_id : '',
        country_id: cardsLength === 0 ? agency.user.data.country_id : '',
        citizenship: 'Srpsko',
        gender: cardsLength === 0 && agency.user.data.gender ? agency.user.data.gender : 'female',
        start_date: '',
        insurance_type: '',
    });

    const [initialDataForEdit, setInitialDataForEdit] = useState<HealthCardsType | CreateHealthCardsType>({
        first_name: '',
        last_name: '',
        parent_name: '',
        jmbg: '',
        street_address: '',
        street_number: '',
        city: '',
        education: '',
        municipality_id: '',
        citizenship: 'Srpsko',
        gender: 'female',
        start_date: '',
    });

    const [dataForEdit, setDataForEdit] = useState(initialDataForEdit);

    useEffect(() => {
        if (editData) {
            setInitialDataForEdit(editData);
            setDataForEdit(editData);
        }
    }, [cardsLength]);

    useEffect(() => {
        setCitizenshipOptions(
            countries.map((country) => {
                return {
                    value: country.id,
                    label: country.name,
                };
            }),
        );
    }, []);

    useEffect(() => {
        if (JSON.stringify(dataForEdit) !== JSON.stringify(initialDataForEdit)) {
            setFormHasBeenEdited(true);
        } else {
            setFormHasBeenEdited(false);
        }
    }, [JSON.stringify(dataForEdit)]);

    useEffect(() => {
        setFormHasDirtyData(true);
    }, [formData]);

    const insuranceType = [
        { label: t('pages.healthCards.insuranceType.child').text, value: 'child' },
        { label: t('pages.healthCards.insuranceType.spouse').text, value: 'spouse' },
        { label: t('pages.healthCards.insuranceType.carrier').text, value: 'insurance_carrier' },
    ];

    const handleSave = async (): Promise<void> => {
        if (
            isInsuranceTypeValid !== '' ||
            isStartDateValid !== '' ||
            isFirstNameValid !== '' ||
            isLastNameValid !== '' ||
            isParentNameValid !== '' ||
            isJmbgValid !== '' ||
            isCityValid !== '' ||
            isAddressOfHeadQuartersValid !== '' ||
            isAddressNumberValid !== '' ||
            isMunicipalityValid !== ''
        )
            setShowError(true);
        else {
            setFormHasDirtyData(false);
            if (cardsLength === 0) {
                changeModal(formData);
                close(false);
            } else {
                loading();
                const res = await communication.createHealthCard(formData);
                if (res.status === 200) {
                    finishLoading();
                    close(false);
                }
            }
        }
    };

    const handleUpdate = async (): Promise<void> => {
        if (
            isFirstNameValidForEdit !== '' ||
            isLastNameValidForEdit !== '' ||
            isParentNameValidForEdit !== '' ||
            isJmbgValidForEdit !== '' ||
            isCityValidForEdit !== '' ||
            isAddressOfHeadQuartersValidForEdit !== '' ||
            isAddressNumberValidForEdit !== ''
        ) {
            return setShowEditError(true);
        } else {
            changeModal(dataForEdit);
            close(false);
        }
    };

    //validations new card
    const isFirstNameValid = useCharLengthValidation(formData?.first_name ? formData?.first_name : '', 3, true);
    const isLastNameValid = useCharLengthValidation(formData?.last_name ? formData?.last_name : '', 3, true);
    const isParentNameValid = useCharLengthValidation(formData?.parent_name ? formData?.parent_name : '', 3, true);
    const isJmbgValid = useNumLengthValidation(formData?.jmbg ? formData?.jmbg : '', 13);
    const isEducationValid = useCharLengthValidation(formData?.education ? formData?.education : '', 3, true);
    const isCityValid = useCharLengthValidation(formData?.city ? formData?.city : '', 3, true);
    const isAddressOfHeadQuartersValid = useCharLengthValidation(
        formData?.street_address ? formData?.street_address : '',
        3,
        true,
    );
    const isAddressNumberValid = useCharLengthValidation(
        formData?.street_number ? formData?.street_number : '',
        1,
        true,
    );
    const isInsuranceTypeValid = useCharLengthValidation(
        formData?.insurance_type ? formData?.insurance_type : '',
        1,
        true,
    );
    const isStartDateValid = useCharLengthValidation(formData?.start_date ? formData?.start_date : '', 1, true);
    const isMunicipalityValid = useCharLengthValidation(
        formData?.municipality_id ? formData?.municipality_id : '',
        1,
        true,
    );
    const isGenderValid = useCharLengthValidation(formData?.gender ? formData?.gender : '', 1, true);

    // validation edit card
    const isFirstNameValidForEdit = useCharLengthValidation(
        dataForEdit?.first_name ? dataForEdit?.first_name : '',
        3,
        true,
    );
    const isLastNameValidForEdit = useCharLengthValidation(
        dataForEdit?.last_name ? dataForEdit?.last_name : '',
        3,
        true,
    );
    const isParentNameValidForEdit = useCharLengthValidation(
        dataForEdit?.parent_name ? dataForEdit?.parent_name : '',
        3,
        true,
    );
    const isJmbgValidForEdit = useNumLengthValidation(dataForEdit?.jmbg ? dataForEdit?.jmbg : '', 13, false);
    const isEducationValidForEdit = useCharLengthValidation(
        dataForEdit?.education ? dataForEdit?.education : '',
        3,
        true,
    );
    const isCityValidForEdit = useCharLengthValidation(dataForEdit?.city ? dataForEdit?.city : '', 3, true);
    const isAddressOfHeadQuartersValidForEdit = useCharLengthValidation(
        dataForEdit?.street_address ? dataForEdit?.street_address : '',
        3,
        true,
    );
    const isAddressNumberValidForEdit = useCharLengthValidation(
        dataForEdit?.street_number ? dataForEdit?.street_number : '',
        1,
        true,
    );

    return (
        <Container>
            <Header>
                <h2>
                    {edit
                        ? t('pages.healthCards.editCardModal.title').text
                        : t('pages.healthCards.newCardModal.title').text}
                </h2>
                <Icon onClick={() => close(true)}>
                    <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                </Icon>
            </Header>
            {!edit && (
                <SelectWrapper>
                    <p>{t('pages.healthCards.newCardModal.insurerType').text}</p>
                    <SelectSearch
                        optionList={cardsLength === 0 ? insuranceType.slice(2) : insuranceType.slice(0, 2)}
                        placeholder={t('pages.healthCards.newCardModal.choseType').text}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            setFormData({ ...formData, insurance_type: data.value });
                        }}
                        validation={showError ? isInsuranceTypeValid : ''}
                    />
                </SelectWrapper>
            )}
            <Input
                type={InputComponentType.Date}
                label={t('pages.healthCards.newCardModal.insuranceStart').text}
                date={
                    dataForEdit && dataForEdit.start_date
                        ? new Date(dataForEdit?.start_date)
                        : formData.start_date
                        ? new Date(formData.start_date)
                        : null
                }
                validation={showError ? isStartDateValid : ''}
                onChange={(value: string) => {
                    edit
                        ? setDataForEdit({
                              ...dataForEdit,
                              start_date: moment(value).format('YYYY-MM-DD').toString(),
                          })
                        : setFormData({
                              ...formData,
                              start_date: moment(value).format('YYYY-MM-DD').toString(),
                          });
                }}
                maxDate={new Date()}
            />
            <Row>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.healthCards.name').text}
                    value={
                        edit ? dataForEdit?.first_name : cardsLength === 0 ? formData?.first_name : formData.first_name
                    }
                    onChange={(value: string) => {
                        editData
                            ? setDataForEdit({ ...dataForEdit, first_name: value })
                            : setFormData({ ...formData, first_name: value });
                    }}
                    validation={showError ? isFirstNameValid : showEditError ? isFirstNameValidForEdit : ''}
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.healthCards.lastName').text}
                    value={edit ? dataForEdit?.last_name : cardsLength === 0 ? formData?.last_name : formData.last_name}
                    onChange={(value: string) => {
                        editData
                            ? setDataForEdit({ ...dataForEdit, last_name: value })
                            : setFormData({ ...formData, last_name: value });
                    }}
                    validation={showError ? isLastNameValid : showEditError ? isLastNameValidForEdit : ''}
                />
            </Row>
            <Input
                type={InputComponentType.Text}
                label={t('pages.healthCards.newCardModal.parentName').text}
                value={
                    edit ? dataForEdit?.parent_name : cardsLength === 0 ? formData?.parent_name : formData.parent_name
                }
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, parent_name: value })
                        : setFormData({ ...formData, parent_name: value });
                }}
                validation={showError ? isParentNameValid : showEditError ? isParentNameValidForEdit : ''}
            />
            <SelectWrapper>
                <p> {t('pages.agency.personalInfo.gender').text}</p>
                <SelectSearch
                    optionList={genders}
                    defaultValue={{
                        label: edit
                            ? currentLang !== 'English'
                                ? dataForEdit.gender === 'male'
                                    ? t('pages.agency.personalInfo.genders.male').text
                                    : t('pages.agency.personalInfo.genders.female').text
                                : dataForEdit.gender
                            : currentLang !== 'English'
                            ? formData.gender === 'male'
                                ? t('pages.agency.personalInfo.genders.male').text
                                : t('pages.agency.personalInfo.genders.female').text
                            : formData.gender,
                        value: edit ? dataForEdit.gender : formData.gender,
                    }}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        editData
                            ? setDataForEdit({ ...dataForEdit, gender: data.value })
                            : setFormData({ ...formData, gender: data.value });
                    }}
                    validation={showError ? isGenderValid : ''}
                />
            </SelectWrapper>
            <Input
                type={InputComponentType.Number}
                label={t('pages.healthCards.id').text}
                value={edit ? dataForEdit?.jmbg : cardsLength === 0 ? formData?.jmbg : formData.jmbg}
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, jmbg: value })
                        : setFormData({ ...formData, jmbg: value });
                }}
                validation={showError ? isJmbgValid : showEditError ? isJmbgValidForEdit : ''}
                maxLength={13}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.healthCards.newCardModal.address').text}
                value={
                    edit
                        ? dataForEdit?.street_address
                        : cardsLength === 0
                        ? formData?.street_address
                        : formData.street_address
                }
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, street_address: value })
                        : setFormData({ ...formData, street_address: value });
                }}
                validation={
                    showError ? isAddressOfHeadQuartersValid : showEditError ? isAddressOfHeadQuartersValidForEdit : ''
                }
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.healthCards.newCardModal.number').text}
                value={
                    edit
                        ? dataForEdit?.street_number
                        : cardsLength === 0
                        ? formData?.street_number
                        : formData.street_number
                }
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, street_number: value })
                        : setFormData({ ...formData, street_number: value });
                }}
                validation={showError ? isAddressNumberValid : showEditError ? isAddressNumberValidForEdit : ''}
            />{' '}
            <Input
                type={InputComponentType.Text}
                label={t('pages.healthCards.city').text}
                value={edit ? dataForEdit?.city : cardsLength === 0 ? formData?.city : formData.city}
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, city: value })
                        : setFormData({ ...formData, city: value });
                }}
                validation={showError ? isCityValid : showEditError ? isCityValidForEdit : ''}
            />
            {cardsLength === 0 && (
                <SelectWrapper>
                    <p>{t('pages.register.rightColumn.form.firstStep.citizenship').text}</p>
                    <SelectSearch
                        optionList={citizenshipOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            setFormData({
                                ...formData,
                                country_id: data.value,
                                citizenship: data.label,
                            });
                            setFormHasBeenEdited(true);
                        }}
                        searchPlaceholder={t('pages.admin.users.tabsContent.actionsForm.searchCitizenship').text}
                        placeholder={t('pages.admin.users.tabsContent.actionsForm.chooseCitizenship').text}
                        defaultValue={citizenshipOptions.find((option) => option.value === formData.country_id)}
                    />
                </SelectWrapper>
            )}
            {cardsLength === 0 && (
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.agency.personalInfo.education').text}
                    value={cardsLength === 0 ? formData?.education : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, education: value });
                        setFormHasBeenEdited(true);
                    }}
                    validation={showError ? isEducationValid : showEditError ? isEducationValidForEdit : ''}
                />
            )}
            {municipalities && (
                <SelectWrapper>
                    {cardsLength === 0 ? (
                        <SelectSearch
                            placeholder={t('pages.healthCards.newCardModal.choseMunicipality').text}
                            optionList={municipalities?.map((m: any) => {
                                return { value: m?.id, label: m?.name };
                            })}
                            handleSelectedValue={(data: { value: string; label: string }) => {
                                editData
                                    ? setDataForEdit({ ...dataForEdit, municipality_id: data.value })
                                    : setFormData({ ...formData, municipality_id: data.value });
                            }}
                            defaultValue={
                                !edit
                                    ? municipalities.find((e) => e.id === formData?.municipality_id) && {
                                          value: formData?.municipality_id,
                                          label: municipalities.find((e) => e.id === formData?.municipality_id)?.name,
                                      }
                                    : municipalities.find((e) => e.id === dataForEdit?.municipality_id) && {
                                          value: dataForEdit?.municipality_id,
                                          label: municipalities.find((e) => e.id === dataForEdit?.municipality_id)
                                              ?.name,
                                      }
                            }
                            validation={showError ? isMunicipalityValid : ''}
                        />
                    ) : (
                        <SelectSearch
                            placeholder={t('pages.healthCards.newCardModal.choseMunicipality').text}
                            optionList={municipalities?.map((m: any) => {
                                return { value: m?.id, label: m?.name };
                            })}
                            handleSelectedValue={(data: { value: string; label: string }) => {
                                editData
                                    ? setDataForEdit({ ...dataForEdit, municipality_id: data.value })
                                    : setFormData({ ...formData, municipality_id: data.value });
                            }}
                            defaultValue={
                                municipalities.find((e) => e.id === dataForEdit?.municipality_id) && {
                                    value: dataForEdit?.municipality_id,
                                    label: municipalities.find((e) => e.id === dataForEdit?.municipality_id)?.name,
                                }
                            }
                            validation={showError ? isMunicipalityValid : ''}
                        />
                    )}
                </SelectWrapper>
            )}
            <Footer>
                {!edit && <p>{t('pages.healthCards.newCardModal.footer').text}</p>}
                {cardsLength === 0 && <p>{t('pages.healthCards.newCardModal.userFooter').text}</p>}
            </Footer>
            <div className="button-container">
                {edit ? (
                    <Button
                        onClick={handleUpdate}
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                    >
                        {t('pages.healthCards.editCardModal.edit').text}
                    </Button>
                ) : (
                    <Button
                        onClick={handleSave}
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                    >
                        {t('pages.healthCards.newCardModal.add').text}
                    </Button>
                )}
            </div>
        </Container>
    );
};

export default NewHealthCardModal;

const Container = styled.div`
    @media screen and (max-width: 600px) {
    }
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
        font-size: 13px;
    }
    p {
        color: var(--gray);
        font-size: 13px;
    }
    .date-container span.date-label {
        font-size: 13px;
    }
    input {
        color: var(--gray) !important;
    }
    .datepicker-input-wrapper {
        background: var(--white);
        border: 1px solid var(--border-color);
        margin-top: 5px;
        svg {
            margin-right: 5px;
        }
    }
`;
const SelectWrapper = styled.div`
    margin-bottom: 20px;
    margin-top: 5px;
    .select {
        width: 100%;

        .css-1jqq78o-placeholder {
            color: var(--gray);
            font-size: 15px;
        }
    }
    .input-dropdown {
        height: 34px;
        color: var(--gray);
    }
    p {
        margin-bottom: 5px;
        font-size: 13px;
        :hover {
            color: var(--purple);
        }
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    padding-bottom: 20px;

    h2 {
        font-size: 24px;
        color: var(--black);
        font-weight: 400;
        text-align: left;
        margin-bottom: 10px;
    }
`;

const Row = styled.div`
    display: flex;
    .input {
        :first-of-type {
            margin-right: 20px;
        }
        width: calc(50% - 20px);
    }
`;

const Footer = styled.div`
    margin-top: 20px;
    display: flow-root;
    p {
        :first-of-type {
            margin-bottom: 10px;
        }
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
`;
