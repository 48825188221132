import { FunctionComponent, useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getAccessToken } from '../functions/auth';
import Navbar from '../components/navbar';
import styled from 'styled-components';
import useWindowSize from '../hooks/useResize';
import { TopBar } from '../components/topBar/TopBar';
import { NAVBAR_WIDTH } from '../constants/index';

const Private: FunctionComponent = () => {
    const token = getAccessToken();
    const size = useWindowSize();

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 1080);
    }, [size.width]);

    if (!token) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            {!isMobileWidth ? (
                <>
                    <WebWrapper className="body private" navbarWidth={`${NAVBAR_WIDTH}px`}>
                        <Navbar />
                        <div className="right-side-wrapper">
                            <TopBar />
                            <div className="content-wrapper">
                                <Outlet />
                            </div>
                        </div>
                    </WebWrapper>
                </>
            ) : (
                <>
                    <Navbar />
                    <MobileBody className="body private">
                        <Outlet />
                    </MobileBody>
                </>
            )}
        </>
    );
};

interface WebWrapperProps {
    navbarWidth: string;
}

const WebWrapper = styled.div<WebWrapperProps>`
    background: var(--body-gray);
    display: flex;
    height: 100%;
    .right-side-wrapper {
        width: calc(100vw - ${({ navbarWidth }) => navbarWidth});
        position: relative;
        height: 100%;
        .content-wrapper {
            padding: 30px 100px 100px;
            position: relative;
            height: 100%;
        }
    }
`;

const MobileBody = styled.div`
    padding: 30px 15px;
    padding-bottom: 104px;
    width: 100%;
    background: var(--body-gray);
    min-height: calc(100vh - 56px);
`;
export default Private;
