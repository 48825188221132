import Button, { ButtonVariant } from '../../../components/button';
import { FunctionComponent, useEffect, useState } from 'react';
import Input, { InputComponentType } from '../../../components/input';
import { useBlocker } from 'react-router-dom';
import ErrorModal from '../../../components/errorModal';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import { ObjectKeys } from '../../../types/objectKeys';
import SelectComponent from '../../../components/select';
import SelectSearch from '../../../components/selectSearch';
import Success from '../../../components/successModal';
import colors from '../../../global/colors';
import communication from '../../../communication';
import moment from 'moment';
import styled from 'styled-components';
import useAgencyIdNumberValidation from '../../../hooks/useAgencyIdNumberValidation';
import useAgencyStore from '../../../store/agency';
import useChangeModalStore from '../../../store/changeModal';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import useEmailValidation from '../../../hooks/useEmailValidation';
import useLanguageStore from '../../../store/language';
import useProfileStore from '../../../store/profile';
import useTranslations from '../../../hooks/useTranslation';
import useUserStore from '../../../store/user';
import useVatNoValidation from '../../../hooks/useVatNoValidation';
import ConfirmModal from '../../../components/confirmModal';
import BankAccountNumber from '../../../components/bankAccountNumber';
import { BankAccountNumberFormData } from '../../../types/bankAccountNumberFormData';

const AgencyProfile: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const [loader, setLoader] = useState(false);
    const t = useTranslations(currentLang);

    const [clickedField, setClickedField] = useState('');

    const [changeHappened, setChangeHappened] = useState(false);

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const { activityCodes, municipalities } = useUserStore();

    const [successfullySaved, setSuccessfullySaved] = useState(false);

    const [formData, setFormData] = useState<ObjectKeys>();
    const [showError, setShowError] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [foreignBankAcc, setForeignBankAcc] = useState<BankAccountNumberFormData>({
        bankAccountNumber1: '',
        bankAccountNumber2: '',
        bankAccountNumber3: '',
    });

    const employmentTypes = [
        { value: 'flat_rate_agency', label: t('pages.agency.profile.flat_rate_agency').text },
        { value: 'employment_relationship', label: t('pages.agency.profile.employment_relationship').text },
        { value: 'retired', label: t('pages.agency.profile.retired').text },
    ];

    //get agency
    useEffect(() => {
        async function getAgency(): Promise<void> {
            setLoader(true);
            const response = await communication.getAgency();
            useAgencyStore.setState({ agency: response.data.data });
            const res = response.data.data;

            setForeignBankAcc({
                bankAccountNumber1: res.foreign_exchange_account_number
                    ? res.foreign_exchange_account_number.split('-')[0]
                    : '',
                bankAccountNumber2: res.foreign_exchange_account_number
                    ? res.foreign_exchange_account_number.split('-')[1]
                    : '',
                bankAccountNumber3: res.foreign_exchange_account_number
                    ? res.foreign_exchange_account_number.split('-')[2]
                    : '',
            });

            setFormData({
                ...res.user.data,
                id: res.id,
                name: res.name,
                full_name: res.full_name,
                pib: res.pib,
                identification_number: res.identification_number,
                activity: {
                    value: res.activityCode?.data.id,
                    label: res.activityCode?.data.code + ' ' + res.activityCode?.data.name,
                },
                date_of_registration: res.date_of_registration,
                city: res.city,
                municipality_id: res.municipality_id,
                phone: res.phone,
                agency_email: res.agency_email,
                street_address: res.street_address,
                street_number: res.street_number,
                employment_changed: false,
                activity_code_id: res.activity_code_id,
                employment_type: res.employment_type,
                first_account_number: res.first_account_number,
                second_account_number: res.second_account_number,
                foreign_exchange_account_number: res.foreign_exchange_account_number,
            });
            setLoader(false);
        }
        getAgency();
    }, []);

    useEffect(() => {
        formData && useProfileStore.setState({ profile: formData });
        if (clickedField !== '') useChangeModalStore.setState({ changeModalVisible: true, source: 'profile' });
    }, [formData]);

    //updating bank accounts
    useEffect(() => {
        const foreignAcc = [
            foreignBankAcc.bankAccountNumber1,
            foreignBankAcc.bankAccountNumber2,
            foreignBankAcc.bankAccountNumber3,
        ];
        const foreign: string = foreignAcc.join('-');

        if (formData) {
            setFormData({
                ...formData,
                foreign_exchange_account_number: foreign.length === 2 ? '' : foreign,
            });
        }
    }, [foreignBankAcc]);

    //validations
    const isNameValid = useCharLengthValidation(formData?.name ? formData?.name : '', 3, true);
    const isEmailValid = useEmailValidation(formData?.agency_email ? formData?.agency_email : '');
    const isAgencyFullNameValid = useCharLengthValidation(formData?.full_name ? formData?.full_name : '', 3, true);
    const isVatNoValid = useVatNoValidation(formData?.pib ? formData?.pib : '');
    const isAgencyIdNumberValid = useAgencyIdNumberValidation(
        formData?.identification_number ? formData?.identification_number : '',
    );
    const isCityAndZipValid = useCharLengthValidation(formData?.city ? formData?.city : '', 3, true);
    const isAddressOfHeadQuartersValid = useCharLengthValidation(
        formData?.street_address ? formData?.street_address : '',
        3,
        true,
    );
    const isAddressNumberValid = useCharLengthValidation(
        formData?.street_number ? formData?.street_number : '',
        1,
        true,
    );

    const handleInputChange = (value: string, maxLength: number, fieldName: string): void => {
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        const truncatedValue = sanitizedValue.slice(0, maxLength);
        setFormData({ ...formData, [fieldName]: truncatedValue });
    };

    const update = (): void => {
        setLoader(true);
        if (
            (isNameValid === '' &&
                isAgencyFullNameValid === '' &&
                isAgencyIdNumberValid === '' &&
                isVatNoValid === '' &&
                isAddressOfHeadQuartersValid === '' &&
                isAddressNumberValid === '' &&
                isEmailValid === '' &&
                isCityAndZipValid === '' &&
                formData?.identification_number?.length === 8 &&
                formData?.foreign_exchange_account_number.length === 0) ||
            formData?.foreign_exchange_account_number.length === 20
        ) {
            try {
                if (formData) {
                    communication
                        .updateAgency(formData.id, formData)
                        .then((res: ObjectKeys) => {
                            if (res.status === 200) {
                                setSuccessfullySaved(true);
                                useAgencyStore.setState({ agency: res.data.data });
                                setChangeHappened(false);
                                setLoader(false);
                            }
                        })
                        .catch((error: any) => {
                            setLoader(false);
                            console.error(error.response.data.message);
                            setErrorMessage(error.response.data.message);
                            setOpenErrorModal(true);
                        });
                }
                useChangeModalStore.setState({ changeModalVisible: false });
                setClickedField('');
            } catch (error: any) {
                setLoader(false);
                setErrorMessage(error.response.data.message);
                setOpenErrorModal(true);
            }
        } else {
            setLoader(false);
            setErrorMessage(t('pages.agency.profile.failed').text);
            setOpenErrorModal(true);
            setShowError(true);
        }
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => changeHappened && currentLocation.pathname !== nextLocation.pathname,
    );

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {loader && <Loader />}
            {formData ? (
                <Content>
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.secondStep.agencyName').text}
                        value={formData.name ? formData.name : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, name: value });
                            setChangeHappened(true);
                        }}
                        validation={showError ? isNameValid : ''}
                        blurHandler={() => handleValidation('name')}
                        maxLength={150}
                    />
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.secondStep.agencyFullName').text}
                        value={formData.full_name ? formData.full_name : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, full_name: value });
                            setChangeHappened(true);
                        }}
                        validation={showError ? isAgencyFullNameValid : ''}
                        blurHandler={() => handleValidation('agencyFullName')}
                        maxLength={150}
                    />
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.register.rightColumn.form.secondStep.vatNumber').text}
                        value={formData.pib ? formData.pib : ''}
                        onChange={(value: string) => {
                            handleInputChange(value, 9, 'pib');
                            setChangeHappened(true);
                        }}
                        validation={showError ? isVatNoValid : ''}
                        blurHandler={() => handleValidation('vatNumber')}
                        maxLength={9}
                    />
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.register.rightColumn.form.secondStep.agencyIdNumber').text}
                        value={formData.identification_number ? formData.identification_number : ''}
                        onChange={(value: string) => {
                            handleInputChange(value, 8, 'identification_number');
                            setChangeHappened(true);
                        }}
                        validation={showError ? isAgencyIdNumberValid : ''}
                        blurHandler={() => handleValidation('agencyIdNumber')}
                        maxLength={8}
                    />
                    <BankAccountNumber
                        showValidationMessage={
                            (foreignBankAcc.bankAccountNumber1 !== '' &&
                                clickedField === 'foreignbankAccountNumber1') ||
                            (foreignBankAcc.bankAccountNumber2 !== '' &&
                                clickedField === 'foreignbankAccountNumber2') ||
                            (foreignBankAcc.bankAccountNumber3 !== '' && clickedField === 'foreignbankAccountNumber3')
                        }
                        t={t}
                        formData={foreignBankAcc}
                        setFormData={setForeignBankAcc}
                        label={t('pages.agency.profile.bankAccForeign').text}
                        handleValidation={(field: string) => handleValidation(field)}
                        type="foreign"
                        clickedField={clickedField}
                        showError={showError}
                    ></BankAccountNumber>
                    {activityCodes && (
                        <SelectWrapper>
                            <p> {t('pages.agency.profile.activityCode').text}</p>
                            <SelectSearch
                                optionList={activityCodes?.map((ac: any) => {
                                    return { value: ac?.id, label: ac?.code + ' ' + ac?.name };
                                })}
                                defaultValue={
                                    formData.activity && {
                                        label: formData.activity.label,
                                        value: formData.activity.value,
                                    }
                                }
                                placeholder={
                                    !formData.activity
                                        ? t('pages.register.rightColumn.form.secondStep.chooseActivityCode').text
                                        : ''
                                }
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    setFormData({ ...formData, activity_code_id: data.value, activity: data });
                                    handleValidation('activityCode');
                                    setChangeHappened(true);
                                }}
                            />
                        </SelectWrapper>
                    )}
                    {formData.date_of_registration && (
                        <Input
                            type={InputComponentType.Date}
                            label={t('pages.register.rightColumn.form.secondStep.agencyDateOfRegistration').text}
                            date={new Date(formData.date_of_registration)}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    date_of_registration: moment(value).format('YYYY-MM-DD').toString(),
                                });
                                setChangeHappened(true);
                            }}
                            blurHandler={() => handleValidation('agencyDateOfRegistration')}
                            maxDate={new Date()}
                        />
                    )}
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.secondStep.cityAndZip').text}
                        value={formData.city ? formData.city : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, city: value });
                            setChangeHappened(true);
                        }}
                        validation={showError ? isCityAndZipValid : ''}
                        blurHandler={() => handleValidation('cityAndZip')}
                    />
                    <EmploymentType>
                        {' '}
                        <p>{t('pages.agency.profile.employment_type').text}</p>
                        <SelectComponent
                            optionList={employmentTypes}
                            handleSelectedValue={(value: string) => {
                                setFormData({ ...formData, employment_type: value });
                                setChangeHappened(true);
                            }}
                            placeholder={t('pages.agency.profile.employment_type_placeholder').text}
                            width={'100%'}
                            defaultSelectedOption={employmentTypes.find(
                                (item) => item.value === formData.employment_type,
                            )}
                            className="select-employment-type"
                        />
                    </EmploymentType>
                    {municipalities && (
                        <SelectWrapper>
                            <p> {t('pages.agency.profile.municipality').text}</p>
                            <SelectSearch
                                optionList={municipalities?.map((m: any) => {
                                    return { value: m?.id, label: m?.name };
                                })}
                                defaultValue={
                                    municipalities.find((e) => e.id === formData.municipality_id) && {
                                        value: formData.municipality_id,
                                        label: municipalities.find((e) => e.id === formData.municipality_id)?.name,
                                    }
                                }
                                placeholder={
                                    !formData.municipality_id
                                        ? t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text
                                        : ''
                                }
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    setFormData({ ...formData, municipality_id: data.value });
                                    handleValidation('municipality');
                                    setChangeHappened(true);
                                }}
                            />
                        </SelectWrapper>
                    )}
                    <Address>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.register.rightColumn.form.secondStep.addressOfHeadQuarters').text}
                            value={formData.street_address ? formData.street_address : ''}
                            onChange={(value: string) => {
                                setFormData({ ...formData, street_address: value });
                                setChangeHappened(true);
                            }}
                            className="address-name"
                            validation={showError ? isAddressOfHeadQuartersValid : ''}
                            blurHandler={() => handleValidation('addressOfHeadQuarters')}
                            maxLength={50}
                        />
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.register.rightColumn.form.secondStep.addressNumber').text}
                            value={formData.street_number ? formData.street_number : ''}
                            onChange={(value: string) => {
                                setFormData({ ...formData, street_number: value });
                                setChangeHappened(true);
                            }}
                            className="address-num"
                            validation={showError ? isAddressNumberValid : ''}
                            blurHandler={() => handleValidation('addressNumber')}
                        />
                    </Address>
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.secondStep.phoneNumber').text}
                        value={formData.phone ? formData.phone : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, phone: value });
                            setChangeHappened(true);
                        }}
                    />
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.firstStep.email').text}
                        value={formData.agency_email ? formData.agency_email : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, agency_email: value });
                            setChangeHappened(true);
                        }}
                        validation={showError ? isEmailValid : ''}
                        blurHandler={() => handleValidation('email')}
                    />
                    <div className="button-container">
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            className="big"
                            size={200}
                            onClick={() => {
                                update();
                            }}
                        >
                            {t('pages.agency.profile.save').text}
                        </Button>
                    </div>
                </Content>
            ) : null}
        </>
    );
};
export default AgencyProfile;

const Content = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
    .bank-number {
        width: 400px;
        position: relative;
    }
    .input-container {
        width: -webkit-fill-available;
        @media screen and (max-width: 768px) {
            margin-bottom: 0px;
            label {
                margin-bottom: 10px;
            }
        }
    }

    input {
        @media screen and (max-width: 768px) {
            border: 1px solid var(--border-color);
            background: var(--white);
            margin-bottom: 20px;
        }
    }

    p {
        font-size: 15px;
        color: var(--gray);
        display: block;
        width: 100%;
        margin: 0;
        padding: 0 0 2px;
        border: 0;
        margin-bottom: 10px;
        :hover {
            color: var(--purple);
        }
    }

    @media screen and (min-width: 1580px) {
        width: 59%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    .select {
        width: 100%;

        .css-1jqq78o-placeholder {
            color: var(--gray);
            font-size: 15px;
        }
    }
`;
const EmploymentType = styled.div`
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    p {
        color: var(--gray);
        margin-top: 5px;
        margin-bottom: 10px;
    }
`;
const Address = styled.div`
    display: flex;
    align-items: center;
`;
