import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useEmailValidation from '../../../hooks/useEmailValidation';
import useWindowSize from '../../../hooks/useResize';
import useLanguageStore from '../../../store/language';
import communication from '../../../communication';
import Input, { InputComponentType } from '../../../components/input';
import Button, { ButtonVariant } from '../../../components/button';
import { ObjectKeys } from '../../../types/objectKeys';
import CityPhoto from '../../../assets/city.png';
import Logo from '../../../assets/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import srJson from '../../../components/language/sr.json';
// import enJson from '../../../components/language/en.json';
// import ruJson from '../../../components/language/ru.json';
// import { flatten } from '../../../functions/flatten';

const AccountActivation: FunctionComponent = () => {
    // const query = window.location.search;
    // const urlParams = new URLSearchParams(query);
    // const langValue = urlParams.get('lang');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();

    const size = useWindowSize();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [isTabletWidth, setIsTabletWidth] = useState(!!size.width && size.width < 1000);

    const isEmailValid = useEmailValidation(email);

    // useEffect(() => {
    //     if (langValue === 'en') {
    //         const flattenData = flatten(enJson);
    //         useLanguageStore.setState({ currentLang: flattenData });
    //         localStorage.setItem('language', 'English');
    //     } else if (langValue === 'ru') {
    //         const flattenData = flatten(ruJson);
    //         useLanguageStore.setState({ currentLang: flattenData });
    //         localStorage.setItem('language', 'Русский');
    //     } else if (langValue === 'sr') {
    //         const flattenData = flatten(srJson);
    //         useLanguageStore.setState({ currentLang: flattenData });
    //         localStorage.setItem('language', 'Srpski');
    //     }
    // }, [langValue, localStorage.language]);

    useEffect(() => {
        setIsTabletWidth(!!size.width && size.width < 1000);
    }, [size.width]);

    const sendActivationLink = async (): Promise<void> => {
        setShowValidationMessage(true);
        setLoading(true);
        if (isEmailValid === '')
            communication.sendActivationLink({ email: email }).then((res: ObjectKeys) => {
                toast.success(res.data);
            });
        setLoading(false);
    };

    return (
        <AccountActivationWrapper>
            <ToastContainer />
            <AccountActivationInner>
                {!isTabletWidth ? (
                    <LeftColumn>
                        <div className="content">
                            <h1>{t('pages.accountActivation.leftColumn.title').text}</h1>
                            <h3>{t('pages.accountActivation.leftColumn.subtitle').text}</h3>
                        </div>
                    </LeftColumn>
                ) : (
                    <></>
                )}
                <RightColumn>
                    <div className="content">
                        <div className="top-block">
                            <h4>{t('pages.accountActivation.rightColumn.title').text}</h4>
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="form">
                            <Input
                                type={InputComponentType.Text}
                                label={t('pages.accountActivation.rightColumn.form.email').text}
                                validation={showValidationMessage ? isEmailValid : ''}
                                value={email}
                                onChange={(value: string) => {
                                    setEmail(value);
                                }}
                            />
                            <div className="submit-btn">
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    className="big"
                                    size={200}
                                    isLoading={loading}
                                    onClick={sendActivationLink}
                                >
                                    {t('buttons.submit').text}
                                </Button>
                            </div>
                        </div>
                        <div className="bottom-block">
                            <span>{t('pages.accountActivation.rightColumn.noAccount').text}</span>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                onClick={() => navigate('/register')}
                                size={150}
                            >
                                {t('buttons.register').text}
                            </Button>
                        </div>
                    </div>
                </RightColumn>
            </AccountActivationInner>
        </AccountActivationWrapper>
    );
};
export default AccountActivation;

const AccountActivationWrapper = styled.div`
    background: var(--purple);
    height: 100%;
`;
const AccountActivationInner = styled.div`
    display: flex;
    height: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 8% 40px 0 40px;
    //Media Queries
    @media only screen and (max-width: 1000px) {
        padding: 5% 40px 0 40px;
    }
    @media only screen and (max-width: 425px) {
        padding: 5% 20px 0 20px;
    }
`;
const LeftColumn = styled.div`
    background: var(--purple);
    color: var(--white);
    flex: 2 1 60%;

    .content {
        max-width: 650px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 10%;
        margin-right: 40px;
        h1 {
            margin-bottom: 30px;
            font-size: 48px;
        }
        h3 {
            line-height: 1.2;
        }
    }
`;
const RightColumn = styled.div`
    background: var(--purple);
    flex: 2 1 40%;
    .content {
        background: var(--white);
        box-shadow: 11px 10px 34px -6px rgba(0, 0, 0, 0.75);
        max-width: 500px;
        //Media Queries
        @media only screen and (max-width: 1000px) {
            margin: 0 auto;
        }
        .top-block {
            background: url(${CityPhoto}) bottom no-repeat;
            background-size: 100% auto;
            border-bottom: 1px solid var(--border-color);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 160px;
            padding: 20px 45px;
            //Media Queries
            @media only screen and (max-width: 425px) {
                padding: 30px;
                height: 130px;
            }
            img {
                width: 110px;
                height: auto;
            }
        }
        .form {
            padding: 40px 45px;
            //Media Queries
            @media only screen and (max-width: 425px) {
                padding: 30px;
            }
            .input-container {
                width: 100%;
            }
            .submit-btn {
                display: flex;
                justify-content: center;
            }
        }
        .bottom-block {
            background: var(--light-gray);
            padding: 20px 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //Media Queries
            @media only screen and (max-width: 500px) {
                padding: 20px 30px;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                span {
                    margin-bottom: 10px;
                }
            }
            span {
                color: var(--gray);
            }
        }
    }
`;
