import './global/styles.css';

import * as Sentry from '@sentry/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import GlobalModal from './components/globalModal';
import GlobalStyle from './global';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import enJson from './components/language/en.json';
import { flatten } from './functions/flatten';
import { router } from './routes';
import ruJson from './components/language/ru.json';
import srJson from './components/language/rs.json';
import useAgencyStore from './store/agency';
import { useEffect } from 'react';
import useGeneralStore from './store/general';
import useLanguageStore from './store/language';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

const App: React.FunctionComponent = () => {
    const currentLangName = localStorage.getItem('language');

    const { agency } = useAgencyStore();
    const oldUser = agency && agency.user?.data?.old_user;

    const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

    const trackingId = process.env.REACT_APP_TRACKING_ID;
    const gtmId = process.env.REACT_APP_GA_MEASUREMENT_ID;

    const googleAuth = process.env.REACT_APP_GOOGLE_AUTH;

    const propertyId = oldUser ? '66713e1c981b6c56477e5f16' : '6671476d981b6c56477e6247';
    const widgetId = oldUser ? '1i0l5l8h7' : '1i0l7u11p';

    const { closeGlobalModal } = useGeneralStore();
    const track_id = trackingId ? trackingId : '';
    const gmt_id = gtmId ? gtmId : '';
    ReactGA.initialize(track_id);
    const tagManagerArgs = {
        gtmId: gmt_id,
    };
    TagManager.initialize(tagManagerArgs);

    Sentry.init({
        dsn: sentryDsn,
        tracesSampleRate: 1.0,
        beforeSend(event: any, hint: any) {
            const error = hint ? hint.originalException.message : '';
            if ((error && error.includes('Request failed with status code 401')) || error.includes('websocket error')) {
                return null; // This prevents the event from being sent to Sentry
            }
            // Send the event to Sentry
            return event;
        },
    });

    // Check for current selected language
    useEffect(() => {
        if (currentLangName && currentLangName === 'English') {
            const flattenData = flatten(enJson);
            useLanguageStore.setState({ currentLang: flattenData });
        } else if (currentLangName && currentLangName === 'Русский') {
            const flattenData = flatten(ruJson);
            useLanguageStore.setState({ currentLang: flattenData });
        } else {
            const flattenData = flatten(srJson);
            useLanguageStore.setState({ currentLang: flattenData });
        }
    }, [currentLangName, localStorage.language]);

    useEffect(() => {
        window.addEventListener('keyup', (e) => {
            if (e.key === 'Escape') {
                closeGlobalModal();
            }
        });
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
                <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                <GlobalStyle />
                <GoogleOAuthProvider clientId={googleAuth ? googleAuth : ''}>
                    <GlobalModal />
                    <TawkMessengerReact propertyId={propertyId} widgetId={widgetId} />
                    <RouterProvider router={router} />
                </GoogleOAuthProvider>
            </Sentry.ErrorBoundary>
        </QueryClientProvider>
    );
};

export default App;
