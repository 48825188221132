import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../global/colors';
import communication from '../../../communication';
import NoItems from '../../../components/noItems';
import Modal from '../../../components/modal';
import Loader from '../../../components/loader';
import DownloadModal from './downloadModal';
import Success from '../../../components/successModal';

const Kpo: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [years, setYears] = useState([]);
    const [year, setYear] = useState('');
    const [downloadModal, setDownloadModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        setLoaderVisible(true);
        async function getYears(): Promise<void> {
            const res = await communication.getYears();
            setYears(res.data.years);
            setLoaderVisible(false);
        }

        getYears();
    }, []);

    return (
        <>
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {downloadModal && (
                <Modal modalVisible={true} closeModal={() => setDownloadModal(false)}>
                    <DownloadModal
                        year={year}
                        close={() => setDownloadModal(false)}
                        message={t('pages.kpo.downloadModal.question').text}
                        modalMessage={(mess: string) => {
                            setSuccess(true);
                            setModalMessage(mess);
                        }}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <Container>
                <Header>
                    <h1>{t('pages.kpo.title').text}</h1>
                </Header>
                <Filters>
                    <label className="second-title">{`${t('pages.kpo.filter_title').text}`}</label>
                </Filters>
                {years?.length > 0 ? (
                    <Books>
                        {years?.map((y) => (
                            <Book
                                key={y}
                                onClick={() => {
                                    setYear(y);
                                    setDownloadModal(true);
                                }}
                            >
                                <div className="text">
                                    <span>{t('pages.kpo.download').text}</span>
                                    <span>
                                        {'\xa0' + y}
                                        {t('pages.kpo.year').text}
                                    </span>
                                </div>
                                <FontAwesomeIcon color={colors.gray} icon={faBook} />
                            </Book>
                        ))}
                    </Books>
                ) : (
                    <NoItems text={t('pages.kpo.noItems.description').text} />
                )}
            </Container>
        </>
    );
};
export default Kpo;
const Container = styled.div`
    vertical-align: top;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const Header = styled.div`
    position: relative;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    display: flex;
    .back {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: var(--white);
        border: 1px solid var(--border-color);
        border-radius: 3px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        &:hover {
            background: var(--purple);
            border: 1px solid var(--purple);
            color: var(--white);
        }
    }
`;
const Books = styled.div`
    margin-top: 30px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
`;
const Book = styled.div`
    display: inline-block;
    position: relative;
    width: 16%;
    height: auto;
    margin: 0 26px 30px 0;
    padding: 16px 20px;
    background: var(--white);
    vertical-align: top;
    border: 1px solid var(--purple);
    cursor: pointer;
    color: var(--gray);
    line-height: 25px;

    span {
        display: block;
        font-size: 13px;
    }

    svg {
        height: 25px;
        width: 22px;
        margin-top: 5px;
    }

    :hover {
        opacity: 0.7;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        margin: 0;
        .text {
            display: flex;
        }
    }
`;
const Filters = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 20px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .show-2 {
            padding: 0;
        }
    }
    @media (max-width: 670px) {
        height: auto;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        // display: inline-block;
    }
    .filter {
        max-width: 100px;
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .filter-search {
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
        }
        label {
            display: inline-block;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .search-filter {
        top: 78px;
        display: flex;
        margin-top: 1.5px;
        @media screen and (max-width: 426px) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        @media (max-width: 1250px) {
            margin-right: 0;
        }
        @media (max-width: 670px) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
        input {
            font-size: 14px;
            width: 150px;
        }
        .input {
            width: 150px;
        }
        .select-search {
            height: 44px;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        width: fit-content;
    }
    .select-month {
        background-color: var(--white);
        color: var(--gray);
        width: 130px;
        @media screen and (max-width: 426px) {
            float: none;
        }
    }
    .select-items {
        background-color: var(--white);
        color: var(--gray);
        width: 80px;
        @media screen and (max-width: 426px) {
            float: none;
        }
    }
    .select__control {
        border-radius: 0px;
    }
    .second-title {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 34px;
        min-width: 120px;
        @media screen and (max-width: 768px) {
            font-size: 15px;
        }
    }
`;
