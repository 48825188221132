import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import communication from '../../../communication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useWindowSize from '../../../hooks/useResize';
import Document from './document';
import { DocumentType } from '../../../types/communication/document';
import Loader from '../../../components/loader';
import Success from '../../../components/successModal';
import Modal from '../../../components/modal';
import DragAndDrop from '../../../components/dragAndDrop';
import { ObjectKeys } from '../../../types/objectKeys';
import Pagination from '../../../components/pagination';

const Documents: FunctionComponent = () => {
    const token = localStorage.getItem('access_token');
    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        orderBy: 'created_at',
        sortedBy: 'desc',
    });
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [addDocument, setAddDocument] = useState(false);
    const [formData, setFormData] = useState(Array<DocumentType>);
    const [meta, setMeta] = useState<ObjectKeys>({});
    const [saved, setSaved] = useState(false);
    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [overSized, setOverSized] = useState(false);
    const [successfullyDeleted, setSuccessfullyDeleted] = useState(false);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        async function getDocuments(): Promise<void> {
            setLoader(true);
            const res = await communication.getDocuments(params);
            const data: Array<DocumentType> = res.data.data;
            setFormData(data);
            setMeta(res.data.meta);
            setLoader(false);
        }

        getDocuments();
    }, [saved, successfullyDeleted, successfullySaved, params.page]);

    const uploadDocument = async (files: Blob[]): Promise<void> => {
        const filesArray = Object.keys(files)
            .map(Number)
            .map((key: number) => [key, files[key]]);
        const allFiles = filesArray.map((singleFile) => {
            return singleFile[1];
        });
        allFiles.forEach(async (element, index) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (element.size > 5242880) {
                setOverSized(true);
            } else {
                try {
                    await communication.uploadDocument(element as Blob);
                    if (allFiles.length === index + 1) {
                        setSuccessfullySaved(true);
                        setMessage(t('pages.agency.documents.successfullyUploaded').text);
                        setAddDocument(false);
                    }
                } catch (e: any) {
                    setMessage(e.response.data.message);
                }
            }
        });
    };

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={message ? message : t('pages.agency.documents.sendModal.success').text}
                    />
                </Modal>
            )}

            {overSized && (
                <Modal modalVisible={true} closeModal={() => setOverSized(false)}>
                    <Success
                        close={() => setOverSized(false)}
                        message={message ? message : t('pages.error.overSize').text}
                    />
                </Modal>
            )}
            {loader && <Loader />}

            <Container>
                <AddDocument className={isMobileWidth ? 'mobile' : 'web'} onClick={() => setAddDocument(!addDocument)}>
                    {!addDocument ? t('pages.agency.documents.add').text : t('pages.agency.documents.back').text}
                    <FontAwesomeIcon
                        icon={!addDocument ? faPlus : faArrowLeft}
                        style={{ color: 'var(--purple)', marginLeft: '5px' }}
                    />
                </AddDocument>
                {!addDocument ? (
                    <Content key={formData.length}>
                        {formData.map((i, index) => (
                            <div key={index}>
                                <Document
                                    token={token ? token : ''}
                                    save={() => setSaved(!saved)}
                                    id={i.id}
                                    name={i.file_name}
                                    t={t}
                                    onSuccess={() => {
                                        setSuccessfullySaved(true);
                                        setLoader(false);
                                    }}
                                    onLoad={() => setLoader(true)}
                                    onDelete={() => setSuccessfullyDeleted(!successfullyDeleted)}
                                />
                            </div>
                        ))}
                        {formData && meta.pagination?.total_pages > 1 && (
                            <Pagination
                                pageCount={meta?.pagination?.total_pages}
                                onPageChange={(e: ObjectKeys) => setParams({ ...params, page: e.selected + 1 })}
                                nextLabel={`${
                                    meta?.pagination?.current_page === meta?.pagination?.total_pages ? '' : '>'
                                }`}
                                previousLabel={`${meta?.pagination?.current_page === 1 ? '' : '<'}`}
                                breakLabel="..."
                                initialPage={meta?.pagination?.current_page - 1}
                            />
                        )}
                    </Content>
                ) : (
                    <div>
                        <DragAndDrop
                            multiple={true}
                            fileTypes={['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'gif']}
                            uploadFunction={uploadDocument}
                            type={'document'}
                            description={t('pages.agency.documents.addDocument').text}
                            preventUpload={false}
                        />
                    </div>
                )}
            </Container>
        </>
    );
};
export default Documents;
const Container = styled.div`
    width: 100%;
    vertical-align: top;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Content = styled.div`
    display: inline-block;
    vertical-align: top;
    color: var(--black);

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const AddDocument = styled.a`
    cursor: pointer;
    font-size: 22px;
    text-align: right;
    margin-top: -55px;
    margin-bottom: 30px;

    &.mobile {
        @media only screen and (max-width: 425px) {
            display: block;
            position: unset;
            float: right;
            margin-top: 0px;
            text-align: end;
        }
        @media screen and (max-width: 768px) {
            display: block;
            position: unset;
            float: right;
        }
    }
`;
