import { Controller, useFormContext } from 'react-hook-form';
import { FunctionComponent, useEffect, useState } from 'react';
import Input, { InputComponentType } from '../../../../components/input';

import { Bank } from '@/communication/banks/types';
import styled from 'styled-components';

import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';

interface Props {
    isNew?: boolean;
    index: number;
    allBanks: Bank[];
    isAllBanksLoading: boolean;
    disabled?: boolean;
}

const BankAccountNumber: FunctionComponent<Props> = ({
    index,
    isNew = false,
    allBanks,
    isAllBanksLoading,
    disabled,
}) => {
    const { control, watch, setValue, setError, trigger } = useFormContext();

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [isBankValid, setIsBankValid] = useState<boolean | 'validating'>('validating');
    const [bankName, setBankName] = useState<string>('');

    const bankAccountNumber1 = watch(`${isNew ? 'newAccounts' : 'accounts'}[${index}].bankAccountNumber1`);
    const bankAccountNumber2 = watch(`${isNew ? 'newAccounts' : 'accounts'}[${index}].bankAccountNumber2`);

    const validateAndSetBankName = async () => {
        const bankFound = allBanks.find((bank) => bank.code === bankAccountNumber1);
        await trigger(`${isNew ? 'newAccounts' : 'accounts'}[${index}].bankAccountNumber1`);

        if (bankFound) {
            setBankName(bankFound.name);
            setIsBankValid(true);
            setValue(`${isNew ? 'newAccounts' : 'accounts'}[${index}].bank_id`, bankFound.id);
        } else {
            setBankName('');
            setIsBankValid(false);
            setError(`${isNew ? 'newAccounts' : 'accounts'}[${index}].bankAccountNumber1`, {
                type: 'onChange',
                message: t('pages.agency.bankAccounts.common.invalidBankCode').text,
            });
        }
    };

    useEffect(() => {
        if (bankAccountNumber1?.length === 3) {
            validateAndSetBankName();
        }
    }, [bankAccountNumber1, allBanks.length, currentLang]);

    return (
        <Container className={isNew ? 'isNew' : ''}>
            <div className="inner">
                <Controller
                    name={`${isNew ? 'newAccounts' : 'accounts'}[${index}].bankAccountNumber1`}
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Input
                            onChange={(inputValue: string) => {
                                if (inputValue !== value) {
                                    // Prevent triggering onChange if oldValue equals newValue to maintain bank validation
                                    onChange(inputValue);
                                }
                            }}
                            value={value}
                            name={name}
                            type={InputComponentType.Number}
                            maxLength={3}
                            validation={error?.message}
                            className="first-bank-number"
                            isDisabled={disabled}
                            placeholder="XXX"
                        />
                    )}
                />
                <span>-</span>
                <Controller
                    name={`${isNew ? 'newAccounts' : 'accounts'}[${index}].bankAccountNumber2`}
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type={InputComponentType.Number}
                            maxLength={13}
                            validation={error?.message}
                            hideBlurOnChange
                            isDisabled={disabled}
                            placeholder="XXXXXXXXXXXXX"
                            customOnBlur={() => {
                                if (bankAccountNumber2.length < 13 && bankAccountNumber2 !== '') {
                                    setValue(
                                        `${isNew ? 'newAccounts' : 'accounts'}[${index}].bankAccountNumber2`,
                                        bankAccountNumber2.padStart(13, '0'),
                                        { shouldDirty: true, shouldValidate: true },
                                    );
                                }
                            }}
                        />
                    )}
                />
                <span>-</span>
                <Controller
                    name={`${isNew ? 'newAccounts' : 'accounts'}[${index}].bankAccountNumber3`}
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type={InputComponentType.Number}
                            maxLength={2}
                            validation={error?.message}
                            className="first-bank-number"
                            isDisabled={disabled}
                            placeholder="XX"
                        />
                    )}
                />
            </div>
            <div className="bankWrapper">
                {isAllBanksLoading || isBankValid === 'validating' ? (
                    <label className="bankPlaceholder">{t('pages.agency.bankAccounts.common.bank').text}</label>
                ) : isBankValid ? (
                    <label className="bankName">{bankName}</label>
                ) : (
                    bankAccountNumber1.length === 3 && (
                        <label className="invalidBank">
                            {t('pages.agency.bankAccounts.common.invalidBankCode').text}
                        </label>
                    )
                )}
            </div>
        </Container>
    );
};

export default BankAccountNumber;

const Container = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    align-items: center;
    width: 100%;
    gap: 1.7rem;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 8px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--purple);
    }

    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        &:hover {
            color: var(--purple);
        }
    }
    &.isNew {
        margin-left: 48px;
    }
    .inner {
        display: flex;
        align-items: center;
        width: fit-content;
        span {
            margin: 0 10px;
            color: var(--border-color);
        }
    }
    .bankWrapper {
        padding-bottom: 6px;
        border-bottom: 1px solid #e7e7e7;
        flex-grow: 1;
        .bankName {
            color: var(--black);
            text-align: left;
            display: block;
        }
        .bankPlaceholder {
            color: var(--gray);
            display: block;
            text-align: left;
        }
        .invalidBank {
            color: red;
        }
    }

    .loaderWrapper {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        span: {
            margin: 0 !important;
        }
    }

    .first-bank-number {
        width: 50px;
        border-color: red;
    }
`;
