import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Public from './public';
import Private from './private';
import Register from '../pages/register';
import NotFoundRoute from '../pages/error';
// import Typography from '../pages/typography';
import Login from '../pages/login';
import Activation from '../pages/register/accountActivation';
import ForgotPassword from '../pages/forgot password';
import ResetPassword from '../pages/forgot password/reset password';
import ConfirmEmail from '../pages/register/confirmEmail';
import PersonalInformation from '../pages/myAgency/personalInformation';
import MySettings from '../pages/mySettings';
import MyAgency from '../pages/myAgency';
import AgencyProfile from '../pages/myAgency/agencyProfile';
import SendData from '../pages/myAgency/sendData';
import Invoices from '../pages/pausal/invoices';
import NotificationsSettings from '../pages/mySettings/notifications';
import Documents from '../pages/myAgency/documents';
import ChangePassword from '../pages/mySettings/changePassword';
import SubscriptionNew from '../pages/mySettings/newSubscription';
import TaxSolution from '../pages/myAgency/taxSolution';
import AuthorizationTransfer from '../pages/myAgency/authorizationTransfer';
import PaymentInstructions from '../pages/myAgency/paymentInstructions';
import BankAccounts from '../pages/myAgency/bankAccounts';
import HealthCards from '../pages/myBusiness/healthCards';
import Einvoices from '../pages/mySettings/einvoices';
import AddLogo from '../pages/myAgency/addLogo';
import DefaultLang from '../pages/mySettings/defaultLang';
import Wallet from '../pages/mySettings/wallet';
import EInvoices from '../pages/pausal/einvoices';
import InvoiceTemplate from '../pages/myAgency/invoiceTemplate';
import Kpo from '../pages/myBusiness/kpo';
import CopyInvoice from '../pages/pausal/invoices/copy';
import EditInvoice from '../pages/pausal/invoices/edit';
import CopyEInvoice from '../pages/pausal/invoices/copy';
import CopyAinvoice from '../pages/pausal/advanceInvoices/copy';
import EditAdvance from '../pages/pausal/advanceInvoices/edit';
import Memorandums from '../pages/myBusiness/memorandums';
import NewMemorandum from '../pages/myBusiness/memorandums/new';
import EditMemorandum from '../pages/myBusiness/memorandums/edit';
import CopyMemorandum from '../pages/myBusiness/memorandums/copy/';
import Clients from '../pages/myBusiness/clients';
import Proforma from '../pages/pausal/proforma';
import AdvanceInvoices from '../pages/pausal/advanceInvoices';
import NewInvoice from '../pages/pausal/invoices/newInvoice';
import NewAdvanceInvoice from '../pages/pausal/advanceInvoices/newInvoice';
import Users from '../pages/admin/users';
import InvoicesAdmin from '../pages/admin/invoices';
import AdvanceAdmin from '../pages/admin/advances';
import EditAdvanceAdmin from '../pages/admin/advances/editAdvance';
import EditInvoiceAdmin from '../pages/admin/invoices/editInvoice';
import ProformaAdmin from '../pages/admin/proforma';
import EditProformaAdmin from '../pages/admin/proforma/editProforma';
import NewProforma from '../pages/pausal/proforma/newProforma';
import EditProforma from '../pages/pausal/proforma/editProforma';
import CopyProforma from '../pages/pausal/proforma/copyProforma';
import TransferOrders from '../pages/myObligations/transferOrders';
import PaymentList from '../pages/myObligations/paymentList';
import UserServices from '../pages/services';
import TransferOrdersAdmin from '../pages/admin/taxes/transferOrderAdmin';
import EcotaxAdmin from '../pages/admin/taxes/ecotaxAdmin';
import Suggestions from '../pages/admin/suggestions';
import Transactions from '../pages/admin/transactions';
// import AdminNotifications from '../pages/admin/notifications';
import AdminNotificationsNew from '../pages/admin/notifications/new';
import Statistics from '../pages/admin/statistics';
import AdminTaxSolutions from '../pages/admin/taxSolutions';
import GoogleAnalytics from '../pages/admin/analytics';
import Services from '../pages/admin/services';
import Requests from '../pages/admin/requests';
import Packages from '../pages/admin/packages';
import Payments from '../pages/admin/payments';
import Reports from '../pages/myBusiness/reports';
import IndependenceTest from '../pages/independenceTest/';
import EAdvanceInvoices from '../pages/pausal/eAdvanceInvoices';
import PaymentSuccess from '../pages/payment/success';
import PaymentError from '../pages/payment/error';
import PaymentCancel from '../pages/payment/cancel';
import ListOfArticles from '../pages/myBusiness/articles';
import Notifications from '../pages/notifications';
import Instructions from '../pages/instructions';
import Ecotax from '../pages/myObligations/ecotax';
import CustomTrasferOrder from '../pages/myObligations/customTransferOrders';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<Public />}>
                <Route path="/register" element={<Register />} />
                <Route path="/login/:activated?" element={<Login />} />
                <Route path="/account-activation" element={<Activation />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
            </Route>
            <Route element={<Private />}>
                <Route path="*" element={<NotFoundRoute />} />
                {/* <Route path="/typography" element={<Typography />} /> */}

                {/* Pausal pages start*/}

                {/* Invoices */}
                <Route path="/" element={<Invoices />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/invoices/kpo" element={<Kpo />} />
                <Route path={'/invoices/copy/:id/:quotation?'} element={<CopyInvoice />} />
                <Route path={'/invoices/edit/:id'} element={<EditInvoice />} />
                <Route path={'/invoices/add/:type'} element={<NewInvoice />} />

                {/* E-invoices */}
                <Route path="/e-invoices" element={<EInvoices />} />
                <Route path="/e-invoices/kpo" element={<Kpo />} />
                <Route path={'/e-invoices/edit/:id'} element={<CopyEInvoice />} />

                {/* Advance-invoices */}
                <Route path="/advance-invoices" element={<AdvanceInvoices />} />
                <Route path={'/advance-invoices/copy/:id/:quotation?'} element={<CopyAinvoice />} />
                <Route path={'/advance-invoices/edit/:id'} element={<EditAdvance />} />
                <Route path={'/advance-invoices/add/:type'} element={<NewAdvanceInvoice />} />

                <Route path="/eadvance-invoices" element={<EAdvanceInvoices />} />

                {/* Clients */}
                <Route path="/clients" element={<Clients />} />

                {/* Proforma */}
                <Route path="/proforma" element={<Proforma />} />
                <Route path="/proforma/copy/:proformaId" element={<CopyProforma />} />
                <Route path="/proforma/edit/:proformaId" element={<EditProforma />} />
                <Route path={'/proforma/add/:proformaType'} element={<NewProforma />} />

                <Route path="/health-cards" element={<HealthCards />} />
                <Route path="/transfer-orders/:year?/:generate?" element={<TransferOrders />} />
                <Route path="/transfer-orders/payment-list/:year?" element={<PaymentList />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/ecotax/:year?" element={<Ecotax />} />
                <Route path="/custom-transfer-orders" element={<CustomTrasferOrder />} />

                <Route>
                    <Route path="/memorandums" element={<Memorandums />} />
                    <Route path="/memorandums/new" element={<NewMemorandum />} />
                    <Route path="/memorandums/copy/:id" element={<CopyMemorandum />} />
                    <Route path="/memorandums/edit/:id" element={<EditMemorandum />} />
                </Route>
                <Route element={<MyAgency />}>
                    <Route path="/agency/profile" element={<AgencyProfile />} />
                    <Route path="/agency/user" element={<PersonalInformation />} />
                    <Route path="/agency/send-data" element={<SendData />} />
                    <Route path="/agency/documents" element={<Documents />} />
                    <Route path="/agency/payment-instructions" element={<PaymentInstructions />} />
                    <Route path="/agency/bank-accounts" element={<BankAccounts />} />
                    <Route path="/agency/tax-solution" element={<TaxSolution />} />
                    <Route path="/agency/authorization-transfer" element={<AuthorizationTransfer />} />
                    <Route path="/agency/add-logo" element={<AddLogo />} />
                    <Route path="/agency/invoice-template" element={<InvoiceTemplate />} />
                </Route>
                <Route>
                    <Route path="/services/:step/:cartId?" element={<UserServices />} />
                </Route>
                <Route element={<MySettings />}>
                    <Route path="/settings/notifications" element={<NotificationsSettings />} />
                    <Route path="/settings/change-password" element={<ChangePassword />} />
                    <Route path="/settings/e-invoice" element={<Einvoices />} />
                    <Route path="/settings/default-lang" element={<DefaultLang />} />
                    <Route path="/settings/subscription/:step/:cartId?" element={<SubscriptionNew />} />
                    <Route path="/settings/wallet/:success?" element={<Wallet />} />
                </Route>
                <Route path="/list-of-articles" element={<ListOfArticles />} />
                {/* Pausal pages end*/}

                <Route>
                    <Route path="/independenceTest" element={<IndependenceTest />} />
                </Route>

                {/* Admin pages start*/}
                <Route path="/admin/users" element={<Users />} />
                <Route path="/admin/invoices" element={<InvoicesAdmin />} />
                <Route path="/admin/invoices/edit/:invoiceId/:userId" element={<EditInvoiceAdmin />} />
                <Route path="/admin/advances" element={<AdvanceAdmin />} />
                <Route path="/admin/advances/edit/:invoiceId/:userId" element={<EditAdvanceAdmin />} />
                <Route path="/admin/proforma" element={<ProformaAdmin />} />
                <Route path="/admin/proforma/edit/:proformaId/:userId" element={<EditProformaAdmin />} />
                <Route path="/admin/transfer-orders/:year?" element={<TransferOrdersAdmin />} />
                <Route path="/admin/ecotax/:year?" element={<EcotaxAdmin />} />
                <Route path="/admin/suggestions" element={<Suggestions />} />
                <Route path="/admin/transactions" element={<Transactions />} />
                {/* <Route path="/admin/notifications" element={<AdminNotifications />} /> */}
                <Route path="/admin/notifications/:type/:id?" element={<AdminNotificationsNew />} />
                <Route path="/admin/statistics" element={<Statistics />} />
                <Route path="/admin/tax" element={<AdminTaxSolutions />} />
                <Route path="/admin/google-analytics" element={<GoogleAnalytics />} />
                <Route path="/admin/packages" element={<Packages />} />
                <Route path="/admin/services" element={<Services />} />
                <Route path="/admin/requests" element={<Requests />} />
                <Route path="/admin/finance" element={<Payments />} />
                {/* Admin pages end*/}

                {/* Payments start */}
                <Route>
                    <Route path="/payment-success" element={<PaymentSuccess />} />
                    <Route path="/payment-error" element={<PaymentError />} />
                    <Route path="/payment-cancel" element={<PaymentCancel />} />
                </Route>
                {/* Payments end */}

                {/* Notifications start */}
                <Route path="/notifications" element={<Notifications />} />
                {/* Notifications end */}

                {/* Instructions start */}
                <Route path="/instructions" element={<Instructions />} />
                {/* Instructions end  */}
            </Route>
        </>,
    ),
);

export default router;
