import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../components/loader';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faDownload, faSquare, faCheckSquare, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../components/select';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import NoItems from '../../../components/noItems';
import moment from 'moment';
import communication from '../../../communication';
import TransferOrderCard from '../../../components/transferOrderCard';
import TransferOrderPreviewModal from '../../../components/transferOrderPreviewModal';
import Modal from '../../../components/modal';
import useAgencyStore from '../../../store/agency';
import { generateQrCode } from '../../../communication/qr';
import { ObjectKeys } from '../../../types/objectKeys';
import AddOrEditModal from './addOrEditModal';
import TransferOrderTemplate from '../../../components/transferOrderTemplate';
import Pagination from '../../../components/pagination';
import ConfirmModal from '../../../components/confirmModal';
import InfoModal from '../../../components/infoModal';
import { getAccessToken } from '../../../functions/auth';
import { formatTwoDecimals } from '../../../functions/format';
import Search from '../../../components/input/search';
import { useNavigate, useParams } from 'react-router-dom';
import Input, { InputComponentType } from '../../../components/input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Success from './successModal';

interface SelectProps {
    label: string;
    value: string;
}
const TransferOrders: FunctionComponent = () => {
    const paramsYear = useParams().year;
    const open_manage_modal = useParams().generate;

    const [limit, setLimit] = useState<any>('10');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [orderTransfers, setOrderTransfers] = useState<ObjectKeys[]>();
    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});
    const [addOrEditModal, setAddOrEditModal] = useState<boolean>(false);
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);
    const [paymentAccounts, setPaymentAccounts] = useState<SelectProps[]>([]);
    const [transferTemplates, setTransferTemplates] = useState<ObjectKeys>([]);
    const [page, setPage] = useState<string>('1');
    const [pagination, setPagination] = useState<any>({});
    const [isDeleteModalActive, setIsDeleteModalActive] = useState<boolean>(false);
    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);
    const [manageOrdersModalActive, setIsManageOrdersModalActive] = useState<boolean>(false);
    const [transferModal, setTransferModal] = useState(false);
    const [transferAction, setTransferAction] = useState<string>('new');
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    const agency = useAgencyStore((props) => props.agency);
    const [showSearch, setShowSearch] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');

    const navigate = useNavigate();
    const [warningModal, setWarningModal] = useState(false);

    const [renderKey, setRenderKey] = useState<number>(0);

    const token = getAccessToken();

    const showSelectOptions = [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ];

    const getAlltransfers = async (): Promise<void> => {
        setIsLoader(true);
        const response = await communication.getAllTransfers(page, limit);
        setPagination(response.data.meta.pagination);
        setOrderTransfers(response.data.data);
        setIsLoader(false);
    };

    const searchTransfers = async (find: string): Promise<void> => {
        setIsLoader(true);
        const response = await communication.searchTransfers(find);
        setPagination(response.data.meta.pagination);
        setOrderTransfers(response.data.data);
        setIsLoader(false);
    };

    const getAllPaymentAccounts = async (): Promise<void> => {
        const res = await communication.getAllPaymentAccounts();
        setPaymentAccounts(res.data.data);
    };

    const generateYearsForFilters = (): void => {
        setYearsOptions([]);
        const currentYear = moment().year();
        const createYear = moment(agency.date_of_registration).year();
        // eslint-disable-next-line for-direction
        for (let i = currentYear; i >= createYear; i--) {
            setYearsOptions((prevState) => [...prevState, { label: i, value: i }]);
        }
    };
    const handleRefresh = async (): Promise<void> => {
        await getAllPaymentAccounts();
        await getAlltransfers();
    };
    useEffect(() => {
        getAllPaymentAccounts();
        generateYearsForFilters();
    }, []);

    useEffect(() => {
        setPage('1');
        getAlltransfers();
    }, [limit]);

    useEffect(() => {
        getAlltransfers();
    }, [page]);

    const handleFindTransfers = (value: string): void => {
        searchTransfers(value);
    };

    const getQrCode = async (transfer: ObjectKeys): Promise<string | undefined> => {
        try {
            const res: any = await generateQrCode(
                transfer?.recipient,
                transfer?.orderer,
                transfer.total_amount,
                transfer.purpose_of_payment,
                transfer.recipient_account,
                transfer.currency,
                true,
                `${
                    transfer.model_number_recipient === '97' || transfer.model_number_recipient === '11'
                        ? transfer.model_number_recipient
                        : '00'
                }${transfer.call_number_recipient}`,
                transfer.payment_code,
            );
            setQrCode(res?.i);
            setIsQrCode(true);

            return res?.i;
        } catch (error) {
            setQrCode('');
            console.error('An error occurred:', error);
            return undefined;
        }
    };

    const downloadTax = async (transfer: ObjectKeys): Promise<void> => {
        try {
            const qrCodeR = await getQrCode(transfer);
            communication.downloadTax(transfer?.id, token, `data:image/jpeg;base64,${qrCodeR}`);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleClickOnCard = async (transfer: ObjectKeys, action: string): Promise<void> => {
        setSelectedTransfer(transfer);
        switch (action) {
            case 'preview':
                setPreviewModal(true);
                await getQrCode(transfer);
                return;
            case 'edit':
                setTransferAction('edit');
                setAddOrEditModal(true);
                return;
            case 'delete':
                setIsDeleteModalActive(true);
                return;
            case 'print':
                await downloadTax(transfer);
                return;
            default:
                break;
        }
    };

    const handleDelete = async (): Promise<void> => {
        setIsLoader(true);
        setIsDeleteModalActive(false);
        const res = await communication.deleteTransferOrder(selectedTransfer?.id);
        if (res.data.message === 'TemplateTransfer () Deleted Successfully.') {
            await getAlltransfers();
        }
        setIsLoader(false);
    };

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}
            <ToastContainer />
            {warningModal && (
                <Modal modalVisible={true} closeModal={() => setWarningModal(false)}>
                    <Success close={() => setWarningModal(false)} />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-tax-modal'}
                >
                    <TransferOrderPreviewModal
                        downloadTax={downloadTax}
                        isQrCode={isQrCode}
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={selectedTransfer}
                    />
                </Modal>
            )}

            {paymentAccounts.length > 0 && addOrEditModal && (
                <Modal
                    modalVisible={addOrEditModal}
                    closeModal={() => setAddOrEditModal(false)}
                    size={'800px'}
                    className={'preview-tax-modal'}
                >
                    <AddOrEditModal
                        onClose={() => setAddOrEditModal(false)}
                        paymentAccounts={paymentAccounts}
                        selectedTransfer={selectedTransfer}
                        action={transferAction}
                        refresh={handleRefresh}
                    />
                </Modal>
            )}

            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <InfoModal
                    setOpenModal={setIsInfoModalActive}
                    message={t('pages.transfers.transfer_upload_success').text}
                    buttonText={t('buttons.ok').text}
                />
            </Modal>

            <Modal modalVisible={isDeleteModalActive} closeModal={() => setIsDeleteModalActive(false)}>
                <ConfirmModal
                    close={() => setIsDeleteModalActive(false)}
                    action={() => handleDelete()}
                    message={'Da li ste sigurni da želite da obrišete nalog za prenos?'}
                />
            </Modal>

            <Header>
                <Title>
                    <h1>{t('navbar.pausal.customTax').text}</h1>
                </Title>

                <Filters>
                    <label className="second-title">{t('pages.transfers.other_transfers').text}</label>
                </Filters>
            </Header>

            <BottomFilters>
                <div className="filter-bottom">
                    <div className="filter">
                        <label className="show-2">{t('pages.invoices.filters.show.label').text}</label>
                        <SelectComponent
                            optionList={showSelectOptions}
                            defaultSelectedOption={showSelectOptions[0]}
                            color={colors.gray}
                            className={'select-items'}
                            handleSelectedValue={(value: any) => {
                                setLimit(value);
                            }}
                            width={'70px'}
                        />
                        <label className="show-2">{t('pages.transfers.orders').text}</label>
                    </div>
                    <div className="filter-search">
                        <Search
                            placeholder={t('pages.invoices.filters.search').text}
                            value={searchValue}
                            onChange={(value: string) => {
                                setShowSearch(true);
                                setSearchValue(value);
                            }}
                            onLeaveOrEnter={(value: string) => {
                                handleFindTransfers(value);
                                setShowSearch(false);
                            }}
                            showSearchNote={showSearch}
                            noteColor={colors.white}
                            className="select-search-filter"
                        />
                    </div>
                </div>
                <Button
                    className={'button-add'}
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        setTransferAction('new');
                        setAddOrEditModal(true);
                    }}
                    icon={true}
                    height={35}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    {t('pages.transfers.add_transfer').text}
                </Button>
            </BottomFilters>
            <Content>
                {orderTransfers?.map((transfer: any) => (
                    <TransferOrderCard
                        key={transfer.id}
                        title={transfer.payment_slip_heading}
                        price={formatTwoDecimals(transfer.total_amount)}
                        currency={transfer.currency}
                        onClick={() => handleClickOnCard(transfer, 'preview')}
                        onEdit={() => handleClickOnCard(transfer, 'edit')}
                        onDelete={() => handleClickOnCard(transfer, 'delete')}
                        onPrint={() => handleClickOnCard(transfer, 'print')}
                    />
                ))}
            </Content>
            {orderTransfers && orderTransfers?.length > 0 && pagination?.total_pages > 0 && (
                <Pagination
                    pageCount={pagination?.total_pages}
                    initialPage={0}
                    onPageChange={(e: object) => {
                        handlePageClick(e);
                    }}
                    nextLabel={(pagination?.current_page === pagination?.total_pages ? '' : '>').toString()}
                    previousLabel={(pagination?.current_page === 1 ? '' : '<').toString()}
                />
            )}
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 74vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow-y: auto;
        }
    }
    label {
        font-size: 14px;
        line-height: 34px;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 478px) {
        flex-direction: column;
    }
`;

const Content = styled.div``;

const BottomFilters = styled.div`
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .filter {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .show-2 {
            font-size: 14px;
            color: var(--gray);
            text-transform: uppercase;
        }
    }
    .purple-title {
        font-size: 14px;
        background: var(--purple);
        color: var(--white);
        margin-bottom: 10px;
        padding: 20px;
        text-transform: uppercase;
    }
`;

const Header = styled.div`
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        button {
            margin-left: 20px;
        }
        @media (max-width: 670px) {
            flex-direction: column;
            button {
                margin-top: 12px;
            }
        }
    }
`;

const Filters = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 20px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .show-2 {
            padding: 0;
        }
    }
    @media (max-width: 670px) {
        height: auto;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        // display: inline-block;
    }
    .filter {
        max-width: 100px;
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .filter-search {
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
        }
        label {
            display: inline-block;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .search-filter {
        top: 78px;
        display: flex;
        gap: 20px;
        @media screen and (max-width: 426px) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        @media (max-width: 1250px) {
            margin-right: 0;
        }
        @media (max-width: 670px) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
        input {
            font-size: 14px;
            width: 150px;
        }
        .input {
            width: 150px;
        }
        .select-search {
            height: 44px;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        width: fit-content;
    }
    .select-month {
        background-color: var(--white);
        color: var(--gray);
        width: 130px;
        @media screen and (max-width: 426px) {
            float: none;
        }
    }
    .select-items {
        background-color: var(--white);
        color: var(--gray);
        width: 80px;
        @media screen and (max-width: 426px) {
            float: none;
        }
    }
    .select__control {
        border-radius: 0px;
    }
    .second-title {
        font-size: 14px;
        line-height: 34px;
        min-width: 120px;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            font-size: 15px;
        }
    }
`;
export default TransferOrders;
