import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import StepOne from './step1/';
import StepTwo from './step2';
import StepThree from './step3';
import StepFour from './step4';
import { ObjectKeys } from '../../types/objectKeys';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import { useParams, useNavigate } from 'react-router-dom';
import communication from '../../communication';
import useAgencyStore from '../../store/agency';

const Services: FunctionComponent = () => {
    const [step, setStep] = useState(1);
    const [cart, setCart] = useState<ObjectKeys>({});
    const navigate = useNavigate();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const paramStep = useParams().step;
    const { agency } = useAgencyStore();

    const params = useParams();
    const paramsCartId = useParams().cartId;

    useEffect(() => {
        setStep(paramStep ? parseInt(paramStep) : 1);
    }, [paramStep]);

    useEffect(() => {
        if (paramsCartId) {
            getCart(paramsCartId);
        }
    }, [paramsCartId, params]);

    useEffect(() => {
        if (cart && cart.items && cart.items.length < 1 && !paramsCartId) {
            setStep(1);
            navigate('/services/1');
        }
    }, [cart]);

    const getCart = (id: string): void => {
        communication
            .getCartById(id)
            .then((res: ObjectKeys) => {
                if (res.data.data.length < 1) {
                    setStep(1);
                    navigate('/services/1');
                } else {
                    const discountedTotal = res.data.data.total_price;
                    const discountPercentage = res.data.data.packet_service_discount;
                    const previousTotal = discountedTotal / (1 - discountPercentage / 100);
                    const items = res.data.data.items.data.map((item: any) => ({
                        created_at: item.service.data.created_at,
                        description_en: item.service.data.description_en,
                        description_rs: item.service.data.description_rs,
                        description_ru: item.service.data.description_ru,
                        id: item.service.data.id,
                        name_en: item.service.data.name_en,
                        name_rs: item.service.data.name_rs,
                        name_ru: item.service.data.name_ru,
                        price: item.price,
                        quantity: item.quantity,
                        type: 'service',
                        updated_at: item.service.data.updated_at,
                    }));
                    setCart({
                        user_id: agency.user.data.id,
                        status: '',
                        total_price: previousTotal,
                        type: 'service',
                        note: res.data.data.note,
                        items: items,
                        discount: res.data.data.packet_service_discount,
                    });
                }
            })
            .catch((err: ObjectKeys) => {
                console.error('err', err);
            });
    };

    return (
        <>
            <Header>
                <div>
                    <h1>{t('navbar.pausal.services').text}</h1>
                </div>
            </Header>
            <Content>
                {step === 1 && (
                    <StepOne
                        nextStep={() => setStep(2)}
                        finallCart={(data: ObjectKeys) => setCart(data)}
                        mainCart={cart}
                    />
                )}
                {step === 2 && (
                    <StepTwo
                        cart={cart}
                        nextStep={() => setStep(3)}
                        previousStep={() => setStep(1)}
                        finallCart={(data: ObjectKeys) => setCart(data)}
                    />
                )}
                {step === 3 && (
                    <StepThree
                        cart={cart}
                        nextStep={() => setStep(4)}
                        updatedCart={(data: ObjectKeys) => setCart(data)}
                        previousStep={() => setStep(2)}
                    />
                )}
                {step === 4 && <StepFour cart={cart} previousStep={() => setStep(3)} />}
            </Content>
        </>
    );
};
const Content = styled.div`
    text-align: center;
    margin-top: 20px;
    p {
        font-weight: 500;
    }
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }
`;
export default Services;
