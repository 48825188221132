import Checkbox from '../checkbox';
import { transformDateFormat } from '../../functions/formatDateTime';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { NotificationType } from '@/communication/notifications/types';
import useLanguageStore from '../../store/language';
import DangerouslySetInnerHTML from '../common/DangerouslySetInnerHtml';

interface Props extends NotificationType {
    checked: boolean;
    onCheck: (id: string) => void;
    onClick: any;
}

export const NotificationItem = ({
    alert_users,
    checked,
    onCheck,
    onClick,
    id,
    title_of_alert_rs,
    title_of_alert_en,
    title_of_alert_ru,
    description_rs,
    description_en,
    description_ru,
    created_at,
}: Props) => {
    const [searchParams] = useSearchParams();
    const searchId = searchParams.get('id');
    const navigate = useNavigate();
    const { currentLangName } = useLanguageStore();
    return (
        <Notification
            className={`${!alert_users.data[0].is_read ? 'is-not-read' : ''}`}
            onClick={() => {
                navigate(`/notifications${searchId !== id ? `?id=${id}` : ''}`);
                if (!alert_users.data[0].is_read) {
                    onClick([id]);
                }
            }}
        >
            <div className={`first-row ${searchId === id ? 'active' : ''}`}>
                <div className="checkbox-title-wrapper">
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            defaultChecked={checked}
                            onChange={(e: MouseEvent) => {
                                onCheck(id);
                            }}
                        />
                    </div>
                    <DangerouslySetInnerHTML
                        htmlContent={
                            currentLangName === 'Srpski'
                                ? title_of_alert_rs
                                : currentLangName === 'English'
                                ? title_of_alert_en
                                : title_of_alert_ru
                        }
                    />
                </div>
                <p className="date">{transformDateFormat(created_at)}</p>
            </div>
            {searchId === id && (
                <DangerouslySetInnerHTML
                    className="second-row"
                    htmlContent={
                        currentLangName === 'Srpski'
                            ? description_rs
                            : currentLangName === 'English'
                            ? description_en
                            : description_ru
                    }
                />
            )}
        </Notification>
    );
};

const Notification = styled.div`
    background-color: var(--white);
    cursor: pointer;
    color: var(--black);
    word-break: break-word;
    &:hover {
        .first-row {
            background-color: #cebcff;
            .checkbox-title-wrapper,
            .date {
                color: var(--black);
            }
        }
    }
    &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
    }
    .checkbox-title-wrapper {
        display: flex;
        gap: 0.5rem;
        font-size: 14px;
        color: var(--gray);
        a {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 50ch;
            &:hover {
                text-decoration: underline;
            }
        }
        .checkbox {
            margin-bottom: 0;
        }
        @media (max-width: 768px) {
            max-width: 120px;
        }
    }
    &.is-not-read {
        font-weight: bold;
        .checkbox-title-wrapper {
            color: var(--black);
        }
        .date {
            color: var(--black);
        }
    }
    .first-row {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        padding: 1.2rem 0.5rem;
        &.active {
            background-color: #cebcff;
            .checkbox-title-wrapper {
                color: var(--black);
            }
            .date {
                color: var(--black);
            }
        }
    }
    .date {
        font-size: 14px;
        color: var(--gray);
    }
    .second-row {
        padding: 1rem 2.7rem;
        font-size: 14px;
        font-style: italic;
        p {
            font-size: 13px;
        }
    }
`;
