import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components';
import colors from '../../../../../global/colors';
import moment from 'moment';
import Loader from '../../../../../components/loader';
import communication from '../../../../../communication';
import { formatTwoDecimals } from '../../../../../functions/format';
import useLanguageStore from '../../../../../store/language';
import useTranslations from '../../../../../hooks/useTranslation';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const BarChart: React.FC = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [labels, setLabels] = useState<string[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<string>('1 mesec');
    const [startDate, setStartDate] = useState<string>(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [loader, setLoader] = useState<boolean>(false);
    const [clientData, setClientData] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(0);

    const dataValues1 = Array.isArray(clientData)
        ? clientData.map((client) => parseFloat(client.total_value_in_rsd))
        : [];

    const data = {
        labels,
        datasets: [
            {
                label: `${t('pages.reports.invoiced').text} (RSD)`,
                data: dataValues1,
                backgroundColor: '#cebcff',
                borderWidth: 3,
                borderColor: 'white',
            },
        ],
    };

    const options = {
        indexAxis: 'y' as const,
        scales: {
            y: {
                display: false,
            },
            x: {
                type: 'linear',
                display: true,
                position: 'bottom',
                ticks: {
                    callback: function (value: any) {
                        return formatTwoDecimals(value.toString());
                    },
                },
            } as any,
        },
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        plugins: {
            datalabels: {
                anchor: 'start',
                align: 'end',
                formatter: function (value: any, context: any) {
                    const company = clientData[context.dataIndex].company_name.replace(/"/g, '');
                    const percentage = `${clientData[context.dataIndex].percentage.toFixed(2)}%`;
                    return `${company}: ${percentage.replace('.', ',')}`;
                },
                color: 'black',
                borderColor: 'black',
                font: {
                    weight: 'normal',
                    size: '14',
                },
            } as any,
        },
    };

    const handlePeriodClick = (period: string): void => {
        setSelectedPeriod(period);
        let daysBack = 0;
        switch (period) {
            case '1 mesec':
                daysBack = 30;
                break;
            case '3 meseca':
                daysBack = 90;
                break;
            case '6 meseci':
                daysBack = 180;
                break;
            case '12 meseci':
                daysBack = 365;
                break;
            default:
                daysBack = 30;
        }
        const newStartDate = moment().subtract(daysBack, 'days').format('YYYY-MM-DD');
        setStartDate(newStartDate);
    };
    const fetchTotale = (): void => {
        if (!startDate) return;
        setLoader(true);
        const propsTotal = {
            start_date: startDate,
            end_date: moment().format('YYYY-MM-DD'),
        };
        communication.getTop5(propsTotal).then((res: any) => {
            setTotal(res.data.data.total_sum);
            setClientData(res.data.data.clients);
            const companyNames = res.data.data.clients.map((client: any) => client.company_name.replace(/"/g, ''));
            setLabels(companyNames);
            setLoader(false);
        });
    };
    useEffect(() => {
        fetchTotale();
    }, [startDate]);

    return (
        <>
            {loader && <Loader />}
            <Content>
                <h3>{t('pages.reports.charts.top_clients').text}</h3>
                <div className="period-container">
                    {t('pages.reports.period').text}:
                    <span
                        className={`period ${selectedPeriod === '1 mesec' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('1 mesec')}
                    >
                        {t('pages.reports.charts.one_month').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '3 meseca' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('3 meseca')}
                    >
                        {t('pages.reports.charts.three_months').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '6 meseci' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('6 meseci')}
                    >
                        {t('pages.reports.charts.six_months').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '12 meseci' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('12 meseci')}
                    >
                        {t('pages.reports.charts.twelve_months').text}
                    </span>
                </div>
                {total === 0 ? (
                    <NoData>{t('pages.reports.charts.noData').text}</NoData>
                ) : (
                    <ResponsiveBar data={data} options={options} />
                )}
                <div className="values">
                    {clientData.map((client, index) => (
                        <p key={index}>
                            {`${index + 1}. ${client.company_name.replace(/"/g, '')}: ${formatTwoDecimals(
                                client.total_value_in_rsd.toString(),
                            )} RSD, `}
                            <strong>{`${client.percentage.toFixed(2).replace('.', ',')}%`}</strong>
                        </p>
                    ))}
                </div>
            </Content>
        </>
    );
};
const ResponsiveBar = styled(Bar)`
    max-width: 100%;
    margin-top: 100px;
`;
const Content = styled.div`
    margin-top: 20px;
    h3 {
        text-align: center;
        margin-bottom: 26px;
        font-size: 20px;
    }
    p {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .period-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin-top: 10px;
        @media only screen and (max-width: 1440px) {
            margin-bottom: 30px;
        }
    }

    .period {
        margin: 0 10px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        cursor: pointer;
        text-align: center;
    }

    .period:hover {
        background-color: #f0f0f0;
    }
    .period.active {
        background-color: ${colors.purple};
        color: #fff;
    }
    @media only screen and (max-width: 768px) {
        .period-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .period {
            margin: 5px 0;
        }
    }
    .values {
        p {
            color: var(--gray);
            font-size: 15px;
        }
        @media only screen and (max-width: 768px) {
            margin-top: 162px;
        }
        @media only screen and (min-width: 769px) and (max-width: 1024px) {
            margin-top: 186px;
        }
        @media only screen and (min-width: 1025px) and (max-width: 1440px) {
            margin-top: 114px;
        }
        @media only screen and (min-width: 1441px) and (max-width: 1560px) {
            margin-top: 41px;
        }
        @media only screen and (min-width: 1561px) and (max-width: 1920px) {
            margin-top: 135px;
        }
        @media only screen and (min-width: 1921px) and (max-width: 2559px) {
            margin-top: 173px;
        }
        @media only screen and (min-width: 2560) {
            margin-top: 315px;
        }
    }
`;
const NoData = styled.div`
    margin-top: 50px;
    padding: 20px;
    text-align: center;
    margin-bottom: 100px;
`;
export default BarChart;
