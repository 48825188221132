import { faUser, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';
import { useDetectClickOutside } from 'react-detect-click-outside';
import Dropdown from '../dropdown';
import { useUserDropdownList } from '../../constants/useUserDropdownList';

export const UserSettingsController = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const closeDropdown = (): void => {
        setDropdownVisible(false);
    };

    const userDropdown = useUserDropdownList();

    const userDropdownItemsList = userDropdown.map((item) => {
        if (!item.handler) {
            return {
                ...item,
                handler: () => closeDropdown(),
            };
        } else {
            return {
                ...item,
                handler: () => {
                    item.handler && item.handler();
                    closeDropdown();
                },
            };
        }
    });

    const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    return (
        <UserSettingsControllerContainer ref={ref}>
            <UserPlace
                onClick={() => {
                    setDropdownVisible(!dropdownVisible);
                }}
            >
                <FontAwesomeIcon icon={faUser} className="user-icon" />
            </UserPlace>
            {dropdownVisible && (
                <DropdownContainer>
                    <Dropdown arrowRight="4px" itemList={userDropdownItemsList} />
                </DropdownContainer>
            )}
        </UserSettingsControllerContainer>
    );
};

const UserSettingsControllerContainer = styled.div`
    position: relative;
`;

export const UserPlace = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--purple);
    cursor: pointer;
`;

export const DropdownContainer = styled.div`
    top: calc(100% + 1.2rem);
    height: 312px;
    width: 182px;
    right: -10px;
    position: absolute;
    z-index: 9999;
`;
