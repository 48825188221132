import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
interface Props {
    className?: string;
    onChange?: Function;
    defaultChecked?: boolean;
    isDisabled?: boolean;
    label?: string | any;
    validation?: string;
    infoMessage?: string;
}
const Checkbox: FunctionComponent<Props> = ({
    className,
    onChange,
    defaultChecked = false,
    label,
    validation,
    infoMessage,
}) => {
    const [isChecked, setChecked] = useState(defaultChecked);

    const change = (): void => {
        setChecked(!isChecked);
        onChange ? onChange(!isChecked) : null;
    };

    useEffect(() => {
        setChecked(defaultChecked);
    }, [defaultChecked]);

    return (
        <StyledCheckboxContainer>
            <StyledCheckbox className={`checkbox cursor-pointer ${className ?? ''}`} onClick={() => onChange}>
                <label className="checkbox-wrapper">
                    <input type="checkbox" checked={isChecked} onChange={change} />
                    <span className="check"></span>
                </label>
                <p>{label}</p>
            </StyledCheckbox>
            {validation !== '' ? (
                <p className="validation-message">{validation}</p>
            ) : (
                (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
            )}
        </StyledCheckboxContainer>
    );
};
const StyledCheckboxContainer = styled.div`
    .validation-message {
        color: var(--danger);
        font-size: 12px;
        margin-top: 2px;
    }
    .info-message {
        font-size: 12px;
        color: var(--gray);
    }
    .notf-checkbox {
        p {
            color: var(--black);
        }
    }
`;
const StyledCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        padding-left: 10px;
        font-size: 15px;
        color: var(--gray);
    }
    .checkbox-wrapper {
        display: block;
        position: relative;
        cursor: pointer;
        height: 14px;
        width: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked ~ .check {
            background-color: var(--white);
            border: 1px solid var(--border-color);
        }

        input:checked ~ .check:after {
            display: block;
        }

        .check {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background: var(--white);
            border: 1px solid var(--border-color);
            box-sizing: border-box;
            border-radius: 2px;
        }

        .check:after {
            content: '';
            position: absolute;
            display: none;
            left: 4.5px;
            top: 1px;
            width: 5px;
            height: 10px;
            border: solid var(--purple);
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
`;

export default Checkbox;
