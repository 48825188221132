import React, { FunctionComponent } from 'react';
import { ObjectKeys } from '@/types/objectKeys';
import styled from 'styled-components';
import moment from 'moment';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';

interface ReportsDataTypes {
    reports?: ObjectKeys;
    filters?: ObjectKeys;
    loading?: boolean;
}
const ReportsClientsTable: FunctionComponent<ReportsDataTypes> = ({ reports, filters, loading }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const formatSum = (sum: number): string => {
        const zero = 0;
        return sum
            ? sum.toLocaleString('sr-SR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : zero.toLocaleString('sr-SR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    return (
        <TableWrapper>
            {filters?.client_id && reports?.client?.data?.company_name && (
                <>
                    <ClientInfo>
                        <div className="data">
                            <p className="data-p">{t('pages.reports.client_name').text}:</p>
                            <p className="data-sub">{reports?.client.data.company_name}</p>
                        </div>
                        <div className="data">
                            <p className="data-p">{t('pages.reports.client_vat').text}: </p>
                            <p className="data-sub">{reports?.client.data.pib}</p>
                        </div>
                        <div className="data">
                            <p className="data-p">{t('pages.reports.client_address').text}: </p>
                            <p className="data-sub">{reports?.client.data.address}</p>
                        </div>
                        <div className="data">
                            <p className="data-p">{t('pages.reports.id').text}:</p>
                            <p className="data-sub">{reports?.client.data.identification_number}</p>
                        </div>
                        <div className="data">
                            <p className="data-p">{t('pages.reports.period').text}:</p>
                            <p className="data-sub">
                                {moment(filters?.start_date).format('DD.MM.YYYY.')} -{' '}
                                {moment(filters?.end_date).format('DD.MM.YYYY.')}
                            </p>
                        </div>
                        <div className="data">
                            <p className="data-p">{t('pages.reports.starting_balance').text}:</p>
                            <p className="data-sub">{formatSum(reports?.invoice_begin_position_value)}</p>
                        </div>
                        <div className="data">
                            <p className="data-p">{t('pages.reports.starting_advance_balance').text}:</p>
                            <p className="data-sub">{formatSum(reports?.prepaid_invoice_begin_position_value)}</p>
                        </div>
                    </ClientInfo>

                    <table className={'first-table'}>
                        <thead>
                            <tr>
                                <th colSpan={2}>{t('pages.reports.prepaid').text} (RSD) </th>
                                <th colSpan={2}>{t('pages.reports.advance_invoiced').text} (RSD) </th>
                                <th colSpan={5}>{t('pages.reports.closed_advances').text} (RSD) </th>
                                <th colSpan={5}>{t('pages.reports.opened_advances').text} (RSD)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>{t('pages.reports.start_balance').text}</td>
                                <td colSpan={2}></td>
                                <td colSpan={5}></td>
                                <td colSpan={5}></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{formatSum(reports?.prepaid_invoice_begin_position_value)}</td>
                                <td colSpan={2}>{formatSum(reports?.client_prepaid_invoice_sent_sum)}</td>
                                <td colSpan={5}>{formatSum(reports?.client_prepaid_invoice_charged_sum)}</td>
                                <td colSpan={5}>{formatSum(reports?.client_prepaid_invoice_saldo)}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}

            {!filters?.client_id && reports?.client?.data?.length > 0
                ? !loading && (
                      <>
                          <p className="title">
                              {t('pages.reports.income_generated_in_the_period').text}:{' '}
                              {moment(filters?.start_date).format('DD.MM.YYYY.')} -{' '}
                              {moment(filters?.end_date).format('DD.MM.YYYY.')}
                          </p>
                          <table className={'second-table'}>
                              <thead>
                                  <tr>
                                      <th>{t('pages.reports.client').text} </th>
                                      <th>{t('pages.reports.invoiced').text} (RSD) </th>
                                      <th>{t('pages.reports.paid').text} (RSD) </th>
                                      <th>{t('pages.reports.saldo').text} (RSD)</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {reports?.client?.data?.map((report: ObjectKeys, index: number) => (
                                      <tr key={index}>
                                          <td>{report.company_name}</td>
                                          <td>{formatSum(report.sum_in_rsd)}</td>
                                          <td>{formatSum(report.paid_sum_in_rsd)}</td>
                                          <td>{formatSum(report.saldo)}</td>
                                      </tr>
                                  ))}
                              </tbody>
                          </table>
                      </>
                  )
                : !loading && (
                      <table className={'second-table'}>
                          <thead>
                              <tr>
                                  <th>{t('pages.reports.invoice_number').text} </th>
                                  <th>{t('pages.reports.date').text}</th>
                                  <th>{t('pages.reports.invoiced').text}</th>
                                  <th>{t('pages.reports.paid').text} </th>
                                  <th>{t('pages.reports.saldo').text} </th>
                                  <th>{t('pages.reports.invoiced').text} (RSD) </th>
                                  <th>{t('pages.reports.paid').text} (RSD) </th>
                                  <th>{t('pages.reports.saldo').text} (RSD)</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>{t('pages.reports.start_balance').text} </td>
                                  <td></td>
                                  <td></td>

                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{formatSum(reports?.invoice_begin_position_value)}</td>
                              </tr>
                              {reports?.invoices?.data?.map((report: ObjectKeys, index: number) => (
                                  <tr key={index}>
                                      <td>{report.invoice_number}</td>
                                      <td>{report.invoice_date}</td>
                                      <td>
                                          {formatSum(parseFloat(report.value))} ({report.currency})
                                      </td>

                                      <td>
                                          {formatSum(parseFloat(report.paid_value))} ({report.currency})
                                      </td>
                                      <td>
                                          {formatSum(report.saldo_value)} ({report.currency})
                                      </td>
                                      <td>{formatSum(parseFloat(report.value_in_rsd))}</td>
                                      <td>{formatSum(parseFloat(report.paid_value_in_rsd))}</td>
                                      <td>{formatSum(report.saldo_value_in_rsd)}</td>
                                  </tr>
                              ))}
                              <tr>
                                  <td>{t('pages.reports.total').text}</td>
                                  <td></td>
                                  <td></td>

                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{formatSum(reports?.client_invoice_saldo)}</td>
                              </tr>
                          </tbody>
                      </table>
                  )}
        </TableWrapper>
    );
};
const TableWrapper = styled.div`
    font-family: Open Sans, sans-serif;
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    table {
        width: 100%;
        overflow-x: auto;
        background-color: #fff;
        border-collapse: collapse;
    }
    .second-table {
        margin-top: 30px;
    }
    .first-table {
        margin-top: 20px;
        width: 100%;
        background-color: #fff;
    }

    td,
    th {
        text-align: center;
    }

    th {
        background-color: #9b7fe9;
        color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        font-size: 15px;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
    }

    td {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        font-size: 15px;
        text-align: center;
        vertical-align: middle;
        color: var(--gray);
    }
    .bold {
        font-weight: 700;
    }

    h1 {
        font-size: 22px;
        color: #4c595f;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .data {
        font-size: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        color: #000;
        margin-bottom: 3px;
        p:first-child {
            font-weight: normal;
        }
        p:last-child {
            margin-left: 10px;
        }
    }
`;

const ClientInfo = styled.div``;
export default ReportsClientsTable;
