import React from 'react';
import styled, { keyframes } from 'styled-components';

const NotificationSkeleton: React.FC = () => {
    return (
        <StyledNotificationCard>
            <CardContent>
                <InfoWrapper className="info-wrapper">
                    <SkeletonBox className="title-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-text"></div>
                    </SkeletonBox>
                    <SkeletonBox className="date-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-text"></div>
                    </SkeletonBox>
                    <SkeletonBox className="views-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-text"></div>
                    </SkeletonBox>
                    <SkeletonBox className="views-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-text"></div>
                    </SkeletonBox>
                    <SkeletonBox className="status-col">
                        <div className="label skeleton"></div>
                        <div className="skeleton skeleton-status"></div>
                    </SkeletonBox>
                </InfoWrapper>
            </CardContent>
        </StyledNotificationCard>
    );
};

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const StyledNotificationCard = styled.div`
    margin-bottom: 10px;
    padding: 16px 20px;
    border-radius: 5px;
    width: 100%;
    background: var(--white);
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
        .info-wrapper {
            opacity: 0.7;
        }
    }
`;

const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
`;

const InfoWrapper = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    gap: 16px;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    .label {
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
        margin-bottom: 8px;
    }

    .title-col,
    .date-col,
    .views-col,
    .status-col {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

const SkeletonBox = styled.div`
    .label.skeleton,
    .skeleton {
        background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
        background-size: 200% 100%;
        animation: ${loadingAnimation} 1.5s infinite;
        border-radius: 4px;
    }

    .skeleton-text {
        height: 18px;
        margin: 8px 0;
        width: 80%;
    }

    .skeleton-status {
        height: 12px;
        width: 60%;
    }

    .skeleton-icon {
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }

    .skeleton-icon {
        margin-left: auto;
    }
`;

export default NotificationSkeleton;
