import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../components/loader';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faDownload, faSquare, faCheckSquare, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../components/select';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import NoItems from '../../../components/noItems';
import moment from 'moment';
import communication from '../../../communication';
import TransferOrderPreviewModal from '../../../components/transferOrderPreviewModal';
import Modal from '../../../components/modal';
import useAgencyStore from '../../../store/agency';
import { generateQrCode } from '../../../communication/qr';
import { ObjectKeys } from '../../../types/objectKeys';
import TransferOrderTemplate from '../../../components/transferOrderTemplate';
import ConfirmModal from '../../../components/confirmModal';
import ManageOrdersModal from './manageOrdersModal';
import InfoModal from '../../../components/infoModal';
import { getAccessToken } from '../../../functions/auth';
import NoTransferOrderModal from './noTransferOrderModal';
import { useNavigate, useParams } from 'react-router-dom';
import Input, { InputComponentType } from '../../../components/input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Success from './successModal';
import TaxSolutionComponent from './taxSolution';
import ToggleSwitch from '../../../components/input/toggleSwitchCustom';
import TransferOrderSkeleton from '../../../components/transferOrderSkeleton';

const TransferOrders: FunctionComponent = () => {
    const paramsYear = useParams().year;
    const open_manage_modal = useParams().generate;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);

    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);

    const [ecotaxTemplates, setEcotaxTemplates] = useState<ObjectKeys>([]);
    const [ecotaxQuarterlyTemplates, setEcotaxQuarterlyTemplates] = useState<ObjectKeys>([]);

    const [isDeleteModalActive, setIsDeleteModalActive] = useState<boolean>(false);
    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);
    const [manageOrdersModalActive, setIsManageOrdersModalActive] = useState<boolean>(false);

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    const agency = useAgencyStore((props) => props.agency);
    const userID = agency.user.data.id;
    const [selectedYear, setSelectedYear] = useState<string>(paramsYear ? paramsYear : moment().year().toString());

    const navigate = useNavigate();
    const [paymentDate, setPaymentDate] = useState<Date | null>(null);
    const [isChecked, setChecked] = useState(false);
    const [paidCheck, setPaidCheck] = useState<boolean>(false);
    const [showChekInput, setShowChekInput] = useState<boolean>(true);
    const [warningModal, setWarningModal] = useState(false);

    const [renderKey, setRenderKey] = useState<number>(0);
    const [renderKey2, setRenderKey2] = useState<number>(0);

    const [annualShow, setAnnualShow] = useState<boolean>(true);

    const token = getAccessToken();

    useEffect(() => {
        if (open_manage_modal) {
            setIsManageOrdersModalActive(true);
        }
    }, [open_manage_modal]);

    useEffect(() => {
        if (paramsYear) {
            setSelectedYear(paramsYear);
            setRenderKey(renderKey + 1);
        }
    }, [paramsYear]);

    const getAllTransferTemplates = async (): Promise<void> => {
        setIsLoading(true);
        communication
            .getAllEcotaxTransfers(selectedYear)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setEcotaxTemplates(res.data.data);
                    if (res.data.data.length > 0) {
                        const parentData = res.data.data[0];
                        const quarterlyData = parentData.quarterly_eko_taxes.data;

                        const ecotaxQuarterly = quarterlyData.map((item: any) => {
                            return {
                                ...parentData,
                                id: item.id,
                                paid_amount: item.paid_amount,
                                paid_date: item.paid_date,
                                quarter: item.quarter,
                                status: item.status,
                                total_amount: item.total_amount,
                            };
                        });

                        setEcotaxQuarterlyTemplates(ecotaxQuarterly);
                    } else {
                        setEcotaxQuarterlyTemplates([]);
                    }

                    setIsLoading(false);
                }
            })
            .catch((err: any) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    useEffect(() => {
        setRenderKey2(renderKey2 + 1);
    }, [ecotaxTemplates]);

    const generateYearsForFilters = (): void => {
        setYearsOptions([]);
        const currentYear = moment().year();
        const createYear = moment(agency.date_of_registration).year();
        // eslint-disable-next-line for-direction
        for (let i = currentYear; i >= createYear; i--) {
            setYearsOptions((prevState) => [...prevState, { label: i, value: i }]);
        }
    };
    useEffect(() => {
        generateYearsForFilters();
    }, []);

    useEffect(() => {
        getAllTransferTemplates();
    }, [selectedYear]);

    const getQrCode = async (transfer: ObjectKeys): Promise<string | undefined> => {
        try {
            const res: any = await generateQrCode(
                transfer?.recipient,
                transfer?.orderer,
                transfer.total_amount,
                transfer.purpose_of_payment,
                transfer.recipient_account,
                transfer.currency,
                true,
                `${transfer.model_number_recipient}${transfer.call_number_recipient}`,
                transfer.payment_code,
            );
            setQrCode(res?.i);
            setIsQrCode(true);

            return res?.i;
        } catch (error) {
            setQrCode('');
            console.error('An error occurred:', error);
            return undefined;
        }
    };

    const getQrCodesForPrint = async (): Promise<string[]> => {
        const qrCodes: string[] = []; // Array to store res.i values
        const qrCodePromises: Promise<void>[] = [];
        // `data:image/jpeg;base64,${qrCode}`
        for (const transfer of ecotaxQuarterlyTemplates as any[]) {
            qrCodePromises.push(
                (async () => {
                    try {
                        const res: any = await generateQrCode(
                            transfer?.recipient,
                            transfer?.orderer,
                            transfer.total_amount,
                            transfer.purpose_of_payment,
                            transfer.recipient_account,
                            transfer.currency,
                            true,
                            `${
                                transfer.model_number_recipient === '97' || transfer.model_number_recipient === '11'
                                    ? transfer.model_number_recipient
                                    : '00'
                            }${transfer.call_number_recipient}`,
                            transfer.payment_code,
                        );
                        const qr = res?.i ? res.i : '""'; // Handle the case where res.i might be missing
                        qrCodes.push(qr); // Push the qrCode to the array
                    } catch (err) {
                        console.error(err);
                        qrCodes.push('""'); // Add a placeholder in case of error
                    }
                })(),
            );
        }

        await Promise.all(qrCodePromises);
        return qrCodes; // Return the array of QR codes
    };

    // const getQrCodesForPrint = async (): Promise<{ [key: string]: string }> => {
    //     const qrCodes: { [key: string]: string } = {};
    //     const qrCodePromises: Promise<void>[] = [];
    //     for (const transfer of ecotaxTemplates as any[]) {
    //         qrCodePromises.push(
    //             (async () => {
    //                 try {
    //                     qrCodes[transfer.id] = '""';
    //                     const res: any = await generateQrCode(
    //                         transfer?.recipient,
    //                         transfer?.orderer,
    //                         transfer.total_amount,
    //                         transfer.purpose_of_payment,
    //                         transfer.recipient_account,
    //                         transfer.currency,
    //                         true,
    //                         `${
    //                             transfer.model_number_recipient === '97' || transfer.model_number_recipient === '11'
    //                                 ? transfer.model_number_recipient
    //                                 : '00'
    //                         }${transfer.call_number_recipient}`,
    //                         transfer.payment_code,
    //                     );
    //                     qrCodes[transfer.id] = res?.i ? res.i : '""';
    //                 } catch (err) {
    //                     console.error(err);
    //                 }
    //             })(),
    //         );
    //     }
    //     await Promise.all(qrCodePromises);
    //     return qrCodes;
    // };

    const downloadEcotaxes = async (): Promise<void> => {
        try {
            const qrCodes = await getQrCodesForPrint();
            communication.downloadEcoTaxes(userID, selectedYear, JSON.stringify(qrCodes));
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const downloadEcotax = async (transfer: ObjectKeys): Promise<void> => {
        try {
            const qrCodeR = await getQrCode(transfer);
            communication.downloadSingleEcotax(transfer?.id, 'year', qrCodeR ? qrCodeR : '');
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleDelete = async (): Promise<void> => {
        setIsLoading(true);
        setIsDeleteModalActive(false);
        const res = await communication.deleteTransferOrder(selectedTransfer?.id);
        if (res.data.message === 'TemplateTransfer () Deleted Successfully.') {
            await getAllTransferTemplates();
        }
        setIsLoading(false);
    };

    return (
        <PageWrapper>
            <ToastContainer />
            {warningModal && (
                <Modal modalVisible={true} closeModal={() => setWarningModal(false)}>
                    <Success close={() => setWarningModal(false)} />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-tax-modal'}
                >
                    <TransferOrderPreviewModal
                        downloadTax={downloadEcotax}
                        isQrCode={isQrCode}
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={selectedTransfer}
                    />
                </Modal>
            )}

            {manageOrdersModalActive && (
                <Modal
                    className={'manage-orders-modal'}
                    modalVisible={manageOrdersModalActive}
                    closeModal={() => {
                        setIsManageOrdersModalActive(false);
                    }}
                >
                    <ManageOrdersModal
                        closeModal={() => setIsManageOrdersModalActive(false)}
                        yearsOptions={yearsOptions}
                        year={selectedYear}
                        setIsLoader={setIsLoading}
                        setOpenInfoModal={setIsInfoModalActive}
                        refresh={() => {
                            setIsManageOrdersModalActive(false);
                            getAllTransferTemplates();
                        }}
                        transferOrders={ecotaxTemplates}
                        key={renderKey2}
                        isEdit={ecotaxTemplates.length > 0}
                    />
                    {/* {ecotaxTemplates.length > 0 ? (
                        <ManageOrdersModal
                            closeModal={() => setIsManageOrdersModalActive(false)}
                            yearsOptions={yearsOptions}
                            year={selectedYear}
                            setIsLoading={setIsLoading}
                            setOpenInfoModal={setIsInfoModalActive}
                            callNumber={ecotaxTemplates[0].call_number_recipient}
                            refresh={() => {
                                getAllTransferTemplates();
                            }}
                            transferOrders={ecotaxTemplates}
                            key={renderKey2}
                            currentMonth={open_manage_modal ? open_manage_modal : currentMonth}
                        />
                    ) : (
                        <NoTransferOrderModal
                            currentLangName={currentLangName}
                            t={t}
                            close={() => setIsManageOrdersModalActive(false)}
                            yearsOptions={yearsOptions}
                            setIsLoading={setIsLoading}
                            setIsInfoModalActive={setIsInfoModalActive}
                            handleRefresh={() => getAllTransferTemplates()}
                            transferModalData={transferModal}
                            year={selectedYear}
                            currentMonth={open_manage_modal ? open_manage_modal : currentMonth}
                        />
                    )} */}
                </Modal>
            )}

            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <InfoModal
                    setOpenModal={setIsInfoModalActive}
                    message={t('pages.transfers.transfer_upload_success').text}
                    buttonText={t('buttons.ok').text}
                />
            </Modal>

            <Modal modalVisible={isDeleteModalActive} closeModal={() => setIsDeleteModalActive(false)}>
                <ConfirmModal
                    close={() => setIsDeleteModalActive(false)}
                    action={() => handleDelete()}
                    message={'Da li ste sigurni da želite da obrišete nalog za prenos?'}
                />
            </Modal>

            <Header>
                <Title>
                    <h1>{t('navbar.pausal.ecotax').text}</h1>
                    <div className="header-right">
                        <div className="header-options">
                            <div className="header-options-buttons">
                                <Button
                                    className={'button'}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    onClick={() => {
                                        navigate('/agency/authorization-transfer');
                                    }}
                                    icon={true}
                                    height={35}
                                >
                                    <FontAwesomeIcon icon={faUserCheck} />
                                    {t('pages.agency.navbar.authorizationTransfer').text}
                                </Button>
                                <Button
                                    className={'button'}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    onClick={() => {
                                        setIsManageOrdersModalActive(true);
                                    }}
                                    icon={true}
                                    height={35}
                                >
                                    <FontAwesomeIcon icon={faPencil} />
                                    {t('pages.transfers.manage_transfers').text}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Title>

                <Filters>
                    <label className="second-title">{`${'Prikaz naloga za eko taksu za godinu'} ${selectedYear}`}</label>
                    <div className="search-filter">
                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions.find((option) => option.value === selectedYear)}
                            className={'select-year'}
                            placeholder={selectedYear}
                            handleSelectedValue={(value: string) => {
                                setSelectedYear(value);
                            }}
                            key={renderKey}
                            width={'100px'}
                        />
                    </div>
                </Filters>
            </Header>
            <ToggleRow>
                <p className="left">Godisnji</p>
                <ToggleSwitch
                    isChecked={annualShow}
                    onChange={() => {
                        setAnnualShow(!annualShow);
                    }}
                    isDisabled={false}
                />
                <p className="right">Kvartalni</p>
            </ToggleRow>
            {/* {showChekInput ? (
                <DateWrapper>
                    {paidCheck ? <span className="text">{t('pages.transfers.payment_lists.paided').text}:</span> : null}
                    <Input
                        type={InputComponentType.Date}
                        date={paymentDate}
                        onChange={(value: Date) => {
                            handleDateChange(value);
                        }}
                        inputClassName="payment-tax-date"
                        placeholder={t('pages.transfers.enter_date').text}
                    />
                    <div
                        onClick={() => {
                            if (!isChecked) return;
                        }}
                        className="checkbox"
                    >
                        <FontAwesomeIcon
                            icon={isChecked ? faCheckSquare : faSquare}
                            className={`check-icon ${isChecked ? 'checked' : ''}`}
                        />
                    </div>
                </DateWrapper>
            ) : null} */}

            <TransferOrderTemplateContainer>
                {annualShow ? (
                    <>
                        {isLoading ? (
                            <SkeletonContainer>
                                <TransferOrderSkeleton />
                            </SkeletonContainer>
                        ) : (
                            <>
                                {ecotaxTemplates.length === 0 ? (
                                    <NoItems text={`Nema naloga za eko taksu u ${selectedYear}. godini`} />
                                ) : (
                                    ecotaxTemplates.map((template: object, index: number) => (
                                        <TransferOrderTemplate
                                            key={index}
                                            template={template}
                                            onClick={() => {
                                                getQrCode(template);
                                                setSelectedTransfer(template);
                                                setPreviewModal(true);
                                            }}
                                            isPaid={paidCheck}
                                        />
                                    ))
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {isLoading ? (
                            <SkeletonContainer>
                                {[...Array(4)].map((_, i) => (
                                    <TransferOrderSkeleton key={i} />
                                ))}
                            </SkeletonContainer>
                        ) : (
                            <>
                                {ecotaxQuarterlyTemplates.length === 0 ? (
                                    <NoItems text={`Nema naloga za eko taksu u ${selectedYear}. godini`} />
                                ) : (
                                    ecotaxQuarterlyTemplates.map((template: object, index: number) => (
                                        <TransferOrderTemplate
                                            key={index}
                                            template={template}
                                            onClick={() => {
                                                getQrCode(template);
                                                setSelectedTransfer(template);
                                                setPreviewModal(true);
                                            }}
                                            isPaid={paidCheck}
                                        />
                                    ))
                                )}
                            </>
                        )}
                    </>
                )}
            </TransferOrderTemplateContainer>
            {ecotaxTemplates.length > 0 && (
                <ButtonContainer>
                    <FontAwesomeIcon
                        icon={faDownload}
                        color={colors.purple}
                        className="download-icon"
                        onClick={() => {
                            if (annualShow) {
                                downloadEcotax(ecotaxTemplates[0]);
                            } else {
                                downloadEcotaxes();
                            }
                        }}
                    />
                </ButtonContainer>
            )}
            <TaxSolutionContainer>
                <TaxSolutionComponent />
            </TaxSolutionContainer>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 74vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow-y: auto;
        }
    }
    label {
        font-size: 14px;
        line-height: 34px;
    }
`;

const TaxSolutionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 100px;
    @media (max-width: 670px) {
        padding: 0;
    }
    .tax-solution {
        margin: 10px;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 478px) {
        flex-direction: column;
    }
`;

const ToggleRow = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        color: var(--gray);
        font-size: 15px;
    }
    .left {
        margin-right: 10px;
    }
    .right {
        margin-left: 10px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    .download-icon {
        font-size: 18px;
        cursor: pointer;
    }
`;

const DateWrapper = styled.div`
    width: fit-content;
    margin-left: auto;
    display: flex;
    align-items: center;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
    .text {
        margin-right: 10px;
        margin-top: 5px;
        font-size: 18px;
    }
    .checkbox {
        margin-left: 10px;
        cursor: pointer;
        .check-icon {
            border: 1px solid var(--purple);
            border-radius: 5px;
            font-size: 18px;
            color: var(--white);
        }
        .check-icon.checked {
            background-color: var(--purple);
        }
    }
`;

const TransferOrderTemplateContainer = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    width: 100%;
`;

const Header = styled.div`
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        button {
            margin-left: 20px;
        }
        @media (max-width: 670px) {
            flex-direction: column;
            button {
                margin-top: 12px;
            }
        }
    }
`;

const Filters = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 20px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .show-2 {
            padding: 0;
        }
    }
    @media (max-width: 670px) {
        height: auto;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        // display: inline-block;
    }
    .filter {
        max-width: 100px;
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .filter-search {
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
        }
        label {
            display: inline-block;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .search-filter {
        top: 78px;
        display: flex;
        gap: 20px;
        @media screen and (max-width: 426px) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        @media (max-width: 1250px) {
            margin-right: 0;
        }
        @media (max-width: 670px) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
        input {
            font-size: 14px;
            width: 150px;
        }
        .input {
            width: 150px;
        }
        .select-search {
            height: 44px;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        width: fit-content;
    }
    .select-month {
        background-color: var(--white);
        color: var(--gray);
        width: 130px;
        @media screen and (max-width: 426px) {
            float: none;
        }
    }
    .select-items {
        background-color: var(--white);
        color: var(--gray);
        width: 80px;
        @media screen and (max-width: 426px) {
            float: none;
        }
    }
    .select__control {
        border-radius: 0px;
    }
    .second-title {
        font-size: 14px;
        line-height: 34px;
        min-width: 120px;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            font-size: 15px;
        }
    }
`;
const SkeletonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .skeleton {
        margin: 10px;
    }
    width: 100%;
`;
export default TransferOrders;
