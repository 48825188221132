import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAllUsersStore from '../../../store/admin/allUsers';
import { ObjectKeys } from '../../../types/objectKeys';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import Statistics from './statistics';
import Filters from '../../pausal/invoices/filters';
import DeleteModal from './deleteModal';
import SearchCustomComponent from '../../../components/customSelect';
import useUserStore from '../../../store/user';
import getStatistics from './getStatistics';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Invoices: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const token = getAccessToken();
    const { allUsers } = useAllUsersStore();
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [invoices, setInvoices] = useState<ObjectKeys>({});
    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');
    const [display, setDisplay] = useState('list');
    const [clickedInvoice, setClickedInvoice] = useState('');
    const [statistics, setStatistics] = useState<ObjectKeys>({});

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
    });

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + ' - ' + user.pib };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        setAllUsersOptions([...userData]);
    }, [allUsers]);

    const getInvoices = (): void => {
        if (selectedUserId) {
            setLoaderVisible(true);
            communication
                .getInvoicesByUserId(selectedUserId, {
                    ...params,
                    search: 'is_prepaid:0;' + formatParams(params.search),
                    searchFields: 'is_prepaid:=;' + formatParams(params.searchFields),
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setInvoices(res?.data);
                    }
                })
                .then(() => {
                    if (setStatistics) {
                        getStatistics(setStatistics, selectedUserId);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        toast.error(error?.response?.data?.message);
                        setInvoices([]);
                    }
                })
                .finally(() => {
                    setLoaderVisible(false);
                });
        }
    };

    useEffect(() => {
        if (selectedUserId) {
            getInvoices();
        }
    }, [selectedUserId, params]);

    const handlePageClick = (e: any): void => {
        setParams({ ...params, page: e.selected + 1 });
    };
    //Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/admin/invoices/edit/${invoiceId}/${selectedUserId}`);
    };

    //Invoice Delete Modal
    const handleDeleteModal = (invoiceNumber: string, invoiceId: string): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={clickedInvoice}
                invoiceNumber={invoiceNumber}
                closeGlobalModal={closeGlobalModal}
                setInvoices={setInvoices}
                selectedUserId={selectedUserId}
                invoiceId={invoiceId}
                onDeleteInvoice={() => {
                    getInvoices();
                }}
            />,
        );
    };

    //Invoice Download
    const handleDownloadInvoice = (
        invoiceId: string,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        if (invoice.type !== 'foreign') {
            generateQrCode(
                agency.name,
                clientData.company_name,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                null,
                '221',
            )
                .then((res: ObjectKeys | any) => {
                    if (res.i) {
                        communication.downloadInvoice(
                            invoiceId,
                            token,
                            invoice.invoice_number,
                            invoice.currency !== 'RSD',
                            false,
                            res.i,
                        );
                    } else {
                        communication.downloadInvoice(
                            invoiceId,
                            token,
                            invoice.invoice_number,
                            invoice.currency !== 'RSD',
                            false,
                            undefined,
                        );
                    }
                })
                .catch((error: ObjectKeys) => {
                    communication.downloadInvoice(
                        invoiceId,
                        token,
                        invoice.invoice_number,
                        invoice.currency !== 'RSD',
                        false,
                        undefined,
                    );
                    console.error(error);
                });
        } else {
            communication.downloadInvoice(
                invoiceId,
                token,
                invoice.invoice_number,
                invoice.currency !== 'RSD',
                false,
                undefined,
            );
        }
    };

    const handleClickedInvoice = (invoiceId: string): void => {
        setClickedInvoice(invoiceId);
    };

    return (
        <>
            <ToastContainer />
            {loaderVisible && <Loader />}
            <div className="invoices page">
                <Header>
                    <h1>{t('pages.invoices.title').text}</h1>
                </Header>
                <SelectWrapper>
                    <p>{t('pages.admin.users.chooseUser').text}</p>
                    <SearchCustomComponent
                        value={allUsersOptions.find((v) => v.value === userBOid)}
                        allUsersOptions={allUsersOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            useUserStore.setState({ userBOid: data.value });
                            setSelectedUserId(data.value);
                        }}
                        placeholder={t('pages.admin.users.chooseUser').text}
                        className="user-selector"
                    />
                </SelectWrapper>
                <Statistics selectedUserId={selectedUserId} setStatistics={setStatistics} statistics={statistics} />
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                <InvoicesList className={`display-${display}`}>
                    {invoices?.data?.length > 0 ? (
                        invoices?.data?.map((invoice: ObjectKeys) => {
                            return (
                                <div key={invoice.id} className={`${display}`}>
                                    <InvoiceCard
                                        type={InvoiceCardComponentType.Invoice}
                                        cardData={invoice}
                                        cardDisplay={display}
                                        handleChange={() => handleChange(invoice.id)}
                                        handleDelete={() => handleDeleteModal(invoice?.invoice_number, invoice.id)}
                                        handleDownload={() =>
                                            handleDownloadInvoice(
                                                invoice?.id,
                                                invoice?.original_data?.agency,
                                                invoice?.client?.data,
                                                invoice.value_in_rsd,
                                                invoice.invoice_number,
                                                invoice.bank_account,
                                                invoice.currency,
                                                true,
                                                invoice,
                                            )
                                        }
                                        handleClick={() => handleClickedInvoice(invoice.id)}
                                        eInvoice={invoice?.is_einvoice}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <NoItems text={t('pages.invoices.noItems.description').text} />
                    )}
                </InvoicesList>
                {invoices?.data?.length > 0 && (
                    <Pagination
                        pageCount={invoices.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            invoices.meta?.pagination?.current_page === invoices.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${invoices.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={invoices.meta?.pagination?.current_page - 1}
                        currentPage={invoices.meta?.pagination?.current_page - 1}
                    />
                )}
            </div>
        </>
    );
};
export default Invoices;

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: 600px) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }
`;
