import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import { downloadFile } from '../communication/download';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class Transfers {
    public getAllEcotaxTransfers(year: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.getEcotaxTransfers(year),
        });
    }
    public createEcotaxOrder(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.createEcotaxTransfer,
            data: data,
        });
    }
    public updateEcotaxOrder(id: string, data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.updateEcotaxTransfer(id),
            data: data,
        });
    }
    public getAllEcotaxSolution(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.getEcotaxSolution,
            params: params,
        });
    }
    public uploadEcotaxSolutions(userId: string, sendData: any, year: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.sendEcotaxSolution,
            data: {
                user_id: userId,
                eco_tax_solution: sendData,
                year: year,
            },
        });
    }
    public deleteEcotaxSolution(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.deleteEcotaxSolution(id),
        });
    }
    public updateEcotaxAnnual(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.updatePayment,
            data: data,
        });
    }
    public updateEcotaxQuarterly(id: string, data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.updateQuarterPayment(id),
            data: data,
        });
    }
    public downloadEcoTaxes(userID: string, year: string, qr_code: string): void {
        fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.downloadEcotaxes(year),
            data: { user_id: userID, qr_code: qr_code },
            responseType: 'blob',
        })
            .then((response: any) => {
                downloadFile(response.data, `EcoTaxes-${year}.pdf`);
            })
            .catch((error: any) => {
                console.error('Error downloading the file:', error);
            });
    }
    public downloadSingleEcotax(id: string, type: string, qr_code: string): void {
        fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.ecotax.downloadSingleEcotax,
            data: { id: id, type: type, qr_code: qr_code ? qr_code : '' },
            responseType: 'blob',
        })
            .then((response: any) => {
                downloadFile(response.data, 'EcoTax.pdf');
            })
            .catch((error: any) => {
                console.error('Error downloading the file:', error);
            });
    }
}
