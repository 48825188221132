import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import SelectSearch from '../../../../components/selectSearch';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../components/loader';
import ErrorModal from '../../../../components/errorModal';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
    closeModal: Function;
    t: Function;
    refetchServices: Function;
    services: ObjectKeys;
    openAddArticleModal: Function;
    setArticleType: Dispatch<SetStateAction<'service' | 'product'>>;
    addArticleToInvoice: Function;
    isUpdating: boolean;
    updatingIndex: number | null;
    updatingId: string | null;
    updateInvoiceArticle: Function;
    deleteInvoiceArticle: Function;
}

const ServiceModal: FunctionComponent<Props> = ({
    closeModal,
    t,
    services,
    refetchServices,
    openAddArticleModal,
    setArticleType,
    addArticleToInvoice,
    isUpdating,
    updatingIndex,
    updatingId,
    updateInvoiceArticle,
    deleteInvoiceArticle,
}) => {
    const [selectedArticleType, setSelectedArticleType] = useState<'service' | 'product'>('service');
    const [loading, setLoading] = useState(false);
    const [serviceOptions, setServiceOptions] = useState([
        {
            label: t('pages.editInvoice.addServiceModal.chooseService').text,
            value: t('pages.editInvoice.addServiceModal.chooseService').text,
        },
    ]);
    const [productOptions, setProductOptions] = useState([
        {
            label: t('pages.editInvoice.addServiceModal.chooseProduct').text,
            value: t('pages.editInvoice.addServiceModal.chooseProduct').text,
        },
    ]);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, _setErrorMessage] = useState('');
    const [defaultArticle, setDefaultArticle] = useState<{ label: string; value: string } | undefined>(undefined);

    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [deleteServiceId, setDeleteServiceId] = useState('');

    const handleDeleteService = async (id: string): Promise<void> => {
        setDeleteServiceId(id);
        setConfirmDeleteModal(true);
    };

    const deleteArticle = async (): Promise<void> => {
        setLoading(true);
        const res = await communication.deleteArticle(deleteServiceId);

        if (res.status === 202) {
            deleteInvoiceArticle && deleteInvoiceArticle(deleteServiceId);
            setDeleteServiceId('');
            toast.success(t('pages.listOfArticles.success').text);
            await refetchServices();
            setLoading(false);
            closeModal();
        } else {
            setLoading(false);
            toast.error(t('pages.listOfArticles.error').text);
        }
    };

    const handleSelect = (service_id: string): void => {
        if (isUpdating && (updatingIndex || updatingIndex === 0) && updatingId) {
            updateInvoiceArticle && updateInvoiceArticle(updatingIndex, service_id);
        } else {
            addArticleToInvoice && addArticleToInvoice(service_id);
        }
    };

    //create service options
    useEffect(() => {
        if (services && services.length > 0) {
            const typeServiceItems = services.filter((f: ObjectKeys) => f.type === 'service');

            const typeServiceOptions = typeServiceItems.map((s: ObjectKeys) => ({
                label: s.name,
                value: s.id,
                icon: <FontAwesomeIcon icon={faTrash} color={colors.danger} />,
            }));

            setServiceOptions([serviceOptions[0], ...typeServiceOptions]);
        }
    }, [services]);

    //create product options
    useEffect(() => {
        if (services && services.length > 0) {
            const typeProductItems = services.filter((f: ObjectKeys) => f.type === 'product');

            const typeProductOptions = typeProductItems.map((s: ObjectKeys) => ({
                label: s.name,
                value: s.id,
                icon: <FontAwesomeIcon icon={faTrash} color={colors.danger} />,
            }));

            setProductOptions([serviceOptions[0], ...typeProductOptions]);
        }
    }, [services]);

    //check if updating flag is triggered and set default article value at select
    useEffect(() => {
        if (isUpdating && (updatingIndex || updatingIndex === 0) && updatingId && services && services?.length > 0) {
            const updatingArticle = services.find((service: { id: string }) => service.id === updatingId);
            if (updatingArticle) {
                setDefaultArticle({
                    label: updatingArticle.name,
                    value: updatingArticle.id,
                });
            }
        }
    }, [isUpdating, updatingIndex, updatingId, services]);

    return (
        <>
            {openErrorModal && <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />}
            <BackDrop onClick={() => closeModal()} />
            <ToastContainer />

            {confirmDeleteModal && (
                <Modal modalVisible={confirmDeleteModal} closeModal={() => setConfirmDeleteModal(false)}>
                    <ConfirmModal
                        action={async () => {
                            setConfirmDeleteModal(false);
                            deleteArticle();
                        }}
                        close={() => setConfirmDeleteModal(false)}
                        active={confirmDeleteModal}
                        message={t('pages.listOfArticles.areYouSure').text}
                    />
                </Modal>
            )}

            {loading && <Loader />}

            <Container>
                <Header>
                    <div
                        className={selectedArticleType === 'service' ? 'tabs selected' : 'tabs'}
                        onClick={() => setSelectedArticleType('service')}
                    >
                        {t('pages.editInvoice.addServiceModal.services').text}
                    </div>
                    <div
                        className={selectedArticleType === 'product' ? 'tabs selected' : 'tabs'}
                        onClick={() => setSelectedArticleType('product')}
                    >
                        {t('pages.editInvoice.addServiceModal.products').text}
                    </div>
                </Header>
                <Body>
                    <div className="select">
                        <label>
                            {
                                t(
                                    selectedArticleType === 'service'
                                        ? 'pages.editInvoice.addServiceModal.chooseService'
                                        : 'pages.editInvoice.addServiceModal.chooseProduct',
                                ).text
                            }
                        </label>
                        <SelectSearch
                            deleteOption
                            color={colors.gray}
                            defaultValue={defaultArticle}
                            placeholder={
                                t(
                                    selectedArticleType === 'service'
                                        ? 'pages.editInvoice.addServiceModal.chooseService'
                                        : 'pages.editInvoice.addServiceModal.chooseProduct',
                                ).text
                            }
                            deleteItemHandler={(e: any) => {
                                handleDeleteService(e);
                            }}
                            handleSelectedValue={(service: any) => {
                                handleSelect(service.value);
                            }}
                            optionList={selectedArticleType === 'service' ? serviceOptions : productOptions}
                        />
                    </div>
                    <div
                        className="add-article"
                        onClick={() => {
                            if (openAddArticleModal) {
                                openAddArticleModal();
                                closeModal();
                                if (selectedArticleType === 'service') {
                                    setArticleType && setArticleType('service');
                                } else {
                                    setArticleType && setArticleType('product');
                                }
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} color={colors.purple} />
                        <p>{t('pages.editInvoice.addServiceModal.insert_new_article').text}</p>
                    </div>
                </Body>
                <Buttons>
                    <Button
                        onClick={() => closeModal()}
                        textTransformNone
                        color={colors.white}
                        variant={ButtonVariant.solid}
                        size={'auto'}
                    >
                        {t('pages.editInvoice.addServiceModal.close').text}
                    </Button>
                </Buttons>
            </Container>
        </>
    );
};
export default ServiceModal;

const BackDrop = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
`;

const Container = styled.div`
    z-index: 2000;
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    width: 495px;
    max-width: 90%;
    background: var(--white);
    border-radius: 6px;
    box-shadow: 0 0 5px 5px var(--light-gray);

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Header = styled.div`
    padding-top: 30px;
    .tabs {
        width: 50%;
        display: inline-block;
        float: left;
        line-height: 30px;
        text-transform: uppercase;
        text-align: center;
        font-size: 20px;
        border-bottom: 3px solid var(--border-color);
        margin-bottom: 20px;
        cursor: pointer;
    }
    .selected {
        border-bottom: 3px solid #9b7fe9;
    }
`;

const Body = styled.div`
    padding: 0 50px 50px;
    label {
        color: var(--gray);
        font-size: 15px;
    }
    .select-search {
        .input-dropdown {
            margin-top: 10px;
            height: 34px;
        }
        width: 100%;
        margin-bottom: 20px;
        .select-with-search__option {
            padding-top: 0px;
            padding-bottom: 0px;
            svg {
                float: right;
                margin-top: -15px;
                z-index: 900;
            }
        }
    }
    .add-article {
        display: flex;
        align-items: center;
        svg {
            margin-right: 5px;
        }
        p {
            color: var(--gray);
            font-size: 15px;
            cursor: pointer;
            :hover {
                color: var(--purple);
            }
        }
    }
`;

const Buttons = styled.div`
    border-top: 1px solid var(--border-color);
    display: flex;
    button {
        border-radius: 0px;
        width: 100%;
        :first-of-type {
            color: var(--purple);
        }
    }
`;
