import React, { FunctionComponent, useEffect, useState } from 'react';
import DragAndDrop from '../../../../components/dragAndDrop';
import styled from 'styled-components';
import useWindowSize from '../../../../hooks/useResize';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import moment from 'moment';
import useAgencyStore from '../../../../store/agency';
type SelectOption = {
    value: string;
    label: string;
};
const TaxSolution: FunctionComponent = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const size = useWindowSize();
    const [yearsForSearch, setYearsForSearch] = useState<Array<string>>([]);
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const agency = useAgencyStore((props) => props.agency);

    const [searchYear, setSearchYear] = useState<SelectOption>({
        value: new Date().getFullYear().toString(),
        label: new Date().getFullYear().toString(),
    });

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        const registartionYear = moment(agency.date_of_registration).year();
        const currentYear = moment().year();
        for (let i = currentYear; i >= registartionYear; i--) {
            setYearsForSearch((prevState) => [...prevState, i.toString()]);
        }
    }, []);

    useEffect(() => {
        if (yearsForSearch[tabIndex]) {
            setSearchYear({ value: yearsForSearch[tabIndex]?.toString(), label: yearsForSearch[tabIndex]?.toString() });
        }
    }, [tabIndex]);

    return (
        <Content className={isMobileWidth ? 'mobile' : 'desktop'}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    {yearsForSearch.map((tab, index) => (
                        <Tab className={'custom-tab'} key={index}>
                            {tab}
                        </Tab>
                    ))}
                </TabList>
                {yearsForSearch.map((tab, index) => (
                    <TabPanel key={index}>
                        <DragAndDrop
                            className={'col-12'}
                            fileTypes={['jpg', 'png', 'doc', 'docx', 'pdf', 'jpeg']}
                            type={'tax'}
                            multiple={true}
                            searchYear={tab}
                            taxCurrentYear={searchYear}
                            preventUpload={false}
                        />
                    </TabPanel>
                ))}
            </Tabs>
        </Content>
    );
};
const Content = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 14.27%;
        }
    }
    .react-tabs__tab--selected {
        background-color: #f7f7f7;
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 3px solid #9b7fe9 !important;
    }
`;
export default TaxSolution;
